import { GetTreatyClaim_getTreatyClaim } from "_graphql/queries/__generated__/GetTreatyClaim";
import _ from "lodash";
import { FC } from "react";

type Props = {
  claim: GetTreatyClaim_getTreatyClaim | null | undefined;
};

const TreatyClaimView: FC<Props> = ({ claim }) => {
  const claimDetails = JSON.parse(claim?.treaty_claim_details || "[]");
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      {/* {JSON.stringify(claim?.treaty_claim_details, null, 2)} */}
      <div>
        <span className="text-xs font-light">Treaty Information</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div className="col-span-2">
            <span className="block text-sm font-light text-gray-700">
              Cedant Name
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {claim?.treaty?.treaty_program?.insurer?.insurer_company_name || "N/A"}
            </div>
          </div>
          <div className=" gap-6 mt-2">
            <div>
              <span className="block text-sm font-light text-gray-700">
                Treaty Program
              </span>
              <div className="mt-1 block w-full sm:text-sm">
                {claim?.treaty?.treaty_program?.treaty_name || "N/A"}
              </div>
            </div>
          </div>
        </div>
      </div>
      {claimDetails.length > 0 && (
        <div className="pt-6">
          <span className="text-xs font-light">Business Class Details</span>
          <div className="grid grid-cols-3 gap-6 mt-2">
            {claimDetails?.map((detail: any, idx: number) => (
              <div>
                <span className="block text-sm font-light text-gray-700">
                  {detail.keydetail}
                </span>
                <div className="mt-1 block w-full sm:text-sm">
                  {detail?.value || "N/A"}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="pt-6">
        <span className="text-xs font-light">claim Details</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <span className="block text-sm font-light text-gray-700">
              Policy Number
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {claim?.claim_number || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Insured
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {claim?.insured_name || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">Date Of Loss</span>
            <div className="mt-1 block w-full sm:text-sm">
              {claim?.date_of_loss || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Currency
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {claim?.treaty?.currency || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Claim Paid
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {claim?.treaty?.currency} {claim?.claim_paid || "N/A"}
            </div>
          </div>
          <div>
            <span className="block text-sm font-light text-gray-700">
              Expected Deductible
            </span>
            <div className="mt-1 block w-full sm:text-sm">
              {claim?.treaty?.currency} {claim?.expected_deductible || "N/A"}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-6">
        <span className="text-xs font-light">Comment</span>
        <div className="grid grid-cols-3 gap-6 mt-2">
          <div>
            <div
              className="mt-1 block w-full sm:text-sm"
              dangerouslySetInnerHTML={{
                __html: claim?.treaty_comment || "N/A",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatyClaimView;
