import { gql } from "@apollo/client";

export const getUnderwriters = gql`
query GetUnderwriters($q: String!, $p: String!, $filters: OfferFilters) {
  underwriters(q: $q, p: $p, filters: $filters) {
    data {
      id
      export_fk_id
      assoc_first_name
      assoc_last_name
      assoc_primary_phonenumber
      assoc_secondary_phonenumber
      assoc_email
      position
      account_state_broker_approval
      account_state_manager_approval
      total_points_earned
      total_points_claimed
    }
    paginatorInfo {
      perPage
      currentPage
      total
    }
  }
}

`;
