import { DocumentTextIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import { OfficeButton, TextLoader } from "components";
import { Action } from "components/buttons/action-button";
import { useTreaty } from "hooks/data/use-treaties";
import { useMatch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import ViewTreatySlipContainer from "./view-placing-slip";
import { useUrlState } from "utils";
import SendPlacingContainer from "./send-emails";
import _ from "lodash";
import { TreatyType } from "_graphql/__generated__/globalTypes";
import LayerLimits from "./sections/layer-limits";
import TreatyAccounts from "./sections/quarters";
import DuplicateTretyCurrencyContainer from "./duplicate-treaty-currency";

const documents = [
  {
    name: "Placing slip",
    description: "View and manage treaty placing slip",
    icon: <DocumentTextIcon className="h-16 text-gray-400" />,
  },
  {
    name: "Cover note",
    description: "View and manage treaty cover note",
    icon: <DocumentTextIcon className="h-16 text-gray-400" />,
  },
];

type Props = {
  allowQuarterlyFiling: boolean;
};

export default function TreatyOverview({ }: Props) {
  const { params } = useMatch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const [modal, setModal] = useUrlState("modal");

  const { loading, treaty } = useTreaty({
    treatyId: params.treaty || "",
  });

  const isProportional = treaty?.treaty_program?.treaty_type === TreatyType.PROPORTIONAL;

  const handleAction = (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) => () => {
    navigate({
      search: (old) => ({
        ...old,
        id,
        modal: action,
      }),
    });
  };

  return (
    <div className="w-full">
      <div className="grid grid-cols-3 gap-8 px-6 py-8">
        <div className="col-span-2 bg-card-500 rounded-xl shadow-lg overflow-hidden">
          <div className="px-6 py-5 border-b border-gray-700/50">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-xl font-semibold text-gray-100">Treaty Information</h3>
                <p className="mt-1 text-sm text-gray-400">Details and information about the treaty</p>
              </div>
              <div className="flex gap-3">
                <OfficeButton
                  size="sm"
                  onClick={() => navigate({
                    to: `${treaty?.treaty_reference?.replaceAll("/", "__")}/manage-treaty`,
                    search: (prev) => ({ ...prev }),
                  })}
                >
                  Manage Treaty
                </OfficeButton>
                <OfficeButton
                  size="sm"
                  onClick={() => navigate({
                    search: (prev) => ({ ...prev, modal: "clone" }),
                  })}
                >
                  Treaty Currency
                </OfficeButton>
              </div>
            </div>
          </div>

          <div className="p-6">
            <dl className="grid grid-cols-1 sm:grid-cols-4 gap-6">
              <TextLoader
                label="Treaty Program"
                value={treaty?.treaty_program?.treaty_name || ""}
                loading={loading}
              />
              <TextLoader
                label="Reference No."
                value={treaty?.treaty_reference || ""}
                loading={loading}
              />
              <TextLoader
                label="Ceding Company"
                value={treaty?.insurer?.insurer_company_name || ""}
                loading={loading}
              />
              <TextLoader
                label="Period"
                value={`${treaty?.treaty_deduction?.treaty_period_from} to ${treaty?.treaty_deduction?.treaty_period_to}` || ""}
                loading={loading}
              />
              <TextLoader
                label="Brokerage"
                value={`${treaty?.treaty_deduction?.brokerage || 0}%`}
                loading={loading}
              />
              <TextLoader
                label="NIC Levy"
                value={`${treaty?.treaty_deduction?.nic_levy || 0}%`}
                loading={loading}
              />
              <TextLoader
                label="Withholding Tax"
                value={`${treaty?.treaty_deduction?.withholding_tax || 0}%`}
                loading={loading}
              />
              <TextLoader
                label="Currency"
                value={treaty?.currency || ""}
                loading={loading}
              />
            </dl>
          </div>
        </div>

        <div className="bg-card-500 rounded-xl shadow-lg overflow-hidden">
          <div className="px-6 py-5 border-b border-gray-700/50">
            <h3 className="text-xl font-semibold text-gray-100">Documents</h3>
            <p className="mt-1 text-sm text-gray-400">Treaty documentation and files</p>
          </div>

          <div className="p-6">
            <ul className="divide-y divide-gray-700/30">
              {documents.map((document) => (
                <li key={document.name} className="py-4 flex items-center justify-between">
                  <div className="flex items-center">
                    <PaperClipIcon className="h-5 w-5 text-gray-400" />
                    <div className="ml-4">
                      <p className="text-sm font-medium text-gray-100">{document.name}</p>
                      <p className="text-xs text-gray-400">{document.description}</p>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <button
                      onClick={handleAction(document.name, "view")}
                      className="text-sm font-medium text-indigo-400 hover:text-indigo-300 transition-colors"
                    >
                      View
                    </button>
                    <button
                      onClick={handleAction(document.name, "send")}
                      className="text-sm font-medium text-indigo-400 hover:text-indigo-300 transition-colors"
                    >
                      Send
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      {isProportional ? <TreatyAccounts treaty={treaty} /> : <LayerLimits treaty={treaty} />}

      {modal && (
        <>
          <ViewTreatySlipContainer
            open={modal === "view"}
            setOpen={(val) => navigate({
              search: (prev) => ({
                ...prev,
                modal: val ? "view" : undefined,
                id: undefined,
              }),
            })}
          />
          <SendPlacingContainer
            open={modal === "send"}
            setOpen={(val) => setModal(val ? "send" : undefined)}
          />
          <DuplicateTretyCurrencyContainer
            open={modal === "clone"}
            setOpen={(val) => setModal(val ? "clone" : undefined)}
          />
        </>
      )}
    </div>
  );
}
