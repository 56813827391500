import { useUrlState } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { Modal, OfficeButton } from "components";
import { TreatyClaimForm, UpdateTreatyClaimForm } from "components/forms";
import {
    MakePaymentOnOffer,
    MakePaymentOnOfferVariables,
} from "_graphql/mutation/__generated__/MakePaymentOnOffer";
import { useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import numeral from "numeral";
import { createPayment } from "_graphql/mutation/payment";
import { useAppSearch, useCurrentUser } from "_graphql/cache/auth";
import { useTreaty } from "hooks/data/use-treaties";
import * as Yup from "yup";
import { useTreatyClaim } from "hooks/data/use-treaty-claims";
import { useEffect } from "react";
import { UpdateClaimCreated, UpdateClaimCreatedVariables } from "_graphql/mutation/__generated__/UpdateClaimCreated";
import { updateTreatyClaim } from "_graphql/mutation/treaty-claims";
import moment from "moment";
import toast from "react-hot-toast";

const validationSchema = Yup.object().shape({
    treaties: Yup.array().of(Yup.object().shape({
        policy_number: Yup.string().required("Policy number is required"),
        claim_number: Yup.string().required("Claim number is required"),
        date_of_loss: Yup.string().required("Date of loss is required"),
        insured: Yup.string().required("Insured is required"),
        claim_paid: Yup.number().typeError("Must be a number").required("Claim paid is required"),
        layer_number: Yup.string().required("Layer number is required"),
        payment_to: Yup.string().required("Payment to is required"),
        treaty_claim_details: Yup.object().shape({
            third_party_claim_amount: Yup.number().typeError("Must be a number").notRequired(),
            survey_fees: Yup.number().typeError("Must be a number").notRequired(),
            legal_fees: Yup.number().typeError("Must be a number").notRequired(),
            salvage: Yup.number().typeError("Must be a number").notRequired(),
            company_name: Yup.string().notRequired(),
            net_liability_amount: Yup.number().typeError("Must be a number").notRequired(),
        }),
        treaty_comment: Yup.string().required("Comment is required"),
    })),
});

export default function UpdateTreatyClaimContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useAppSearch()
    const navigate = useNavigate<LocationGenerics>();
    const [execute, { loading }] = useMutation<
        UpdateClaimCreated,
        UpdateClaimCreatedVariables
    >(updateTreatyClaim);
    const { loading: fetchingClaim, claim } = useTreatyClaim({
        treatyClaimId: searchParams?.id || "",
    });
    const form = useFormik<any>({
        validationSchema,
        initialValues: {
            treaty: null,
            policy_number: "",
            claim_number: "",
            date_of_loss: "",
            insured: "",
            claim_paid: 0,
            layer_number: "",
            payment_to: "",
            treaty_claim_details: {
                third_party_claim_amount: 0,
                survey_fees: 0,
                legal_fees: 0,
                salvage: 0,
                company_name: "",
                net_liability_amount: 0,
            },
            treaty_comment: "",
        },
        onSubmit: async (values, helpers) => {
            execute({
                variables: {
                    treatyClaimId: claim?.treaty_claim_id,
                    claims: {
                        policy_number: values.policy_number,
                        claim_number: values.claim_number,
                        date_of_loss: values.date_of_loss,
                        insured_name: values.insured,
                        claim_paid: values.claim_paid,
                        layer_number: values.layer_number,
                        expected_deductible: values.expected_deductible,
                        treaty_claim_details: JSON.stringify(values.treaty_claim_details),
                        treaty_comment: values.treaty_comment,
                        claim_date: moment().format("YYYY-MM-DD"),
                    },
                }
            }).then(({ data }) => {
                if (data?.updateClaimCreated) {
                    helpers.resetForm();
                    toast.success("Claim updated successfully");
                    navigate({
                        search(prev) {
                            return {
                                ...prev,
                                id: undefined,
                                modal: undefined,
                            };
                        },
                    });
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Could not update claim");
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });


    useEffect(() => {
        if (claim) {
            const treatyDetails = JSON.parse(claim?.treaty_claim_details || "{}");
            form.setValues({
                treaty: claim.treaty,
                policy_number: claim.policy_number,
                claim_number: claim.claim_number,
                date_of_loss: claim.date_of_loss,
                insured: claim.insured_name,
                claim_paid: claim.claim_paid,
                layer_number: claim.layer_number,
                expected_deductible: claim.expected_deductible,
                // payment_to: claim.treaty_claim_details,
                treaty_claim_details: {
                    third_party_claim_amount: treatyDetails?.third_party_claim_amount,
                    survey_fees: treatyDetails?.survey_fees,
                    legal_fees: treatyDetails?.legal_fees,
                    salvage: treatyDetails?.salvage,
                    company_name: treatyDetails?.company_name,
                    net_liability_amount: treatyDetails?.net_liability_amount,
                },
                treaty_comment: claim.treaty_comment || "",
            });
        }
    }, [claim]);



    return (
        <Modal
            open={open}
            setOpen={() => {
                setOpen(false);
                navigate({
                    search(prev) {
                        return {
                            ...prev,
                            rate: undefined,
                            modal: undefined,
                        };
                    },
                });
            }}
            title="Update Treaty Claim"
            loading={fetchingClaim}
            description="Update Treaty Claim"
            renderActions={() => (
                <div className="flex flex-row justify-end">
                    <OfficeButton
                        onClick={() => {
                            form.submitForm();
                        }}
                    >
                        {loading ? "Please wait ..." : "Update Claim"}
                    </OfficeButton>
                </div>
            )}
        >
            {/* {JSON.stringify(form.errors, null, 2)} */}
            <UpdateTreatyClaimForm form={form} />
        </Modal>
    );
}



