import { useUrlState, wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { Modal } from "components";
import * as Yup from "yup";
import { ClaimForm } from "components/forms";
import { useOffer } from "hooks/data/use-offers";
import { LocationGenerics } from "router/location";
import { useSearch } from "react-location";
import numeral from "numeral";
import { updateOfferClaim } from "_graphql/mutation/claims";
import { useEffect } from "react";
import { UpdateClaimAmount, UpdateClaimAmountVariables } from "_graphql/mutation/__generated__/UpdateClaimAmount";
import { useAppLocation } from "_graphql/cache/auth";

export const valiadationSchema = (amount: number) =>
    Yup.object().shape({
        amount: Yup.number().min(0, "Number must be greater than 0")
            .typeError("Amount must be a number")
            .min(1, "Shouldn't be lesser than 1")
            .max(amount, `Shouldn't be more than ${numeral(amount).format("3,3.##")}`)
            .required("Amount is required"),
        claim_date: Yup.string().required("Claim Date is required"),
        comment: Yup.string().notRequired(),
    });

export default function UpdateContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { params } = useAppLocation()
    const [rate, setRate] = useUrlState("rate");
    const { offer, loading: fetching } = useOffer({
        offer_id: params?.offer || "",
    });
    const [create, { loading }] = useMutation<UpdateClaimAmount, UpdateClaimAmountVariables>(
        updateOfferClaim
    );
    const amountSpent =
        offer?.offer_claims?.reduce(
            (total, currentClaim) => total + (currentClaim?.claim_amount || 0),
            0
        ) || 0;

    const claimableAmount =
        numeral(offer?.fac_sum_insured || 0)
            .subtract(amountSpent)
            .multiply(rate || 1)
            .value() || 0;

    const form = useFormik<any>({
        initialValues: {
            offer_id: offer?.offer_id,
            currency: offer?.offer_detail?.currency,
            rate: 1,
            amount: searchParams?.amount || "",
            claim_date: searchParams?.from || "",
            comment: "",
            anotherCurrency: false,
        },
        validationSchema: valiadationSchema(
            numeral(claimableAmount || 0)
                .multiply(rate || 1)
                .value() || 0
        ),
        onSubmit: async (variables) => {
            await create({
                variables: {
                    claimData: {
                        claim_amount: variables.amount,
                        claim_date: variables.claim_date,
                        offer_id: variables.offer_id,
                        claim_comment: variables.comment,
                        currency: variables.currency,
                        rate: variables.rate,
                    },
                    offerClaimId: searchParams?.id || "",
                },
            }).then(({ data }) => {
                if (data?.updateClaimAmount) {
                    toast.success(
                        "Claimm updated Successfully",
                    );
                    refetch?.();
                    form.resetForm();
                } else {
                    toast.error(
                        "Could not update claim, Please try again or contact support",
                    );
                }
            });
        },
        onReset: () => {
            setOpen(false);
        },
    });

    useEffect(() => {
        if (form.values.rate) {
            setRate(form.values.rate);
        } else {
            setRate(1);
        }
    }, [form.values.rate, rate, setRate]);
    useEffect(() => {
        if (offer) {
            // form.setFieldValue("currency", searchParams?.amount);
            form.setFieldValue("offer_id", offer.offer_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offer]);

    return (
        <Modal
            open={open}
            setOpen={(value) => {
                setOpen(value);
                form.resetForm();
            }}
            size="4xl"
            loading={fetching}
            title="Modify Claim"
            description="Modify a claim"
            renderActions={() => (
                <>
                    <button
                        type="button"
                        disabled={loading}
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={wrapClick(form.handleSubmit)}
                    >
                        {loading ? "Modifying Claim..." : "Modify Claim"}
                    </button>
                </>
            )}
        >
            <ClaimForm
                form={form}
                offer={offer}
                claimableAmount={
                    numeral(claimableAmount).multiply(form.values.rate).value() || 0
                }
            />
        </Modal>
    );
}
