import { gql } from "@apollo/client";


export const manuallyCreateClaims = gql`
mutation ManuallyCreateClaims($treatyId: ID, $claims: [TreatyClaimData]) {
  manuallyCreateClaims(treaty_id: $treatyId, claims: $claims)
}
`

export const updateTreatyClaim = gql`
mutation UpdateClaimCreated($treatyClaimId: ID, $claims: TreatyClaimData) {
  updateClaimCreated(treaty_claim_id: $treatyClaimId, claims: $claims)
}
`

export const deleteTreatyClaim = gql`
mutation DeleteClaimCreated($treatyClaimId: ID) {
  deleteClaimCreated(treaty_claim_id: $treatyClaimId)
}
`