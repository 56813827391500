import clsx from "clsx";
import { Loader } from "components";
import { useOffer } from "hooks/data/use-offers";
import moment from "moment";
import numeral from "numeral";
import { useMatch } from "react-location";
import { LocationGenerics } from "router/location";

export default function OfferOverView() {
  const { params } = useMatch<LocationGenerics>();
  const { loading, offer } = useOffer({
    offer_id: params.offer || "",
  });

  if (loading) return <Loader />;

  return (
    <div className="flex-1 px-6 py-4">
      <div className="bg-card-500 rounded-xl shadow-lg overflow-hidden">
        <div className="px-6 py-5 border-b border-gray-700/50">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-xl font-semibold text-gray-100">
                Offer Information
              </h3>
              <p className="mt-1 text-sm text-gray-400">
                Comprehensive details about the insurance offer
              </p>
            </div>
            <div>
              <span
                className={clsx(
                  "inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium",
                  {
                    "bg-indigo-400/10 text-indigo-400 ring-1 ring-indigo-400/30":
                      offer?.offer_status === "OPEN",
                    "bg-yellow-400/10 text-yellow-400 ring-1 ring-yellow-400/30":
                      offer?.offer_status === "PENDING",
                    "bg-teal-400/10 text-teal-400 ring-1 ring-teal-400/30":
                      offer?.offer_status === "CLOSED",
                  }
                )}
              >
                {offer?.offer_status}
              </span>
            </div>
          </div>
        </div>

        <div className="p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
            <InfoCard
              label="Class of Business"
              value={offer?.classofbusiness?.business_name}
            />
            <InfoCard
              label="Policy Number"
              value={offer?.offer_detail?.policy_number}
            />
            <InfoCard
              label="Reinsured"
              value={offer?.insurer?.insurer_company_name}
            />
            <InfoCard
              label="Insured"
              value={offer?.offer_detail?.insured_by}
            />
            <InfoCard
              label="Period of Insurance"
              value={`${moment(offer?.offer_detail?.period_of_insurance_from).format("DD/MM/YYYY")} to ${moment(offer?.offer_detail?.period_of_insurance_to).format("DD/MM/YYYY")}`}
            />
            <InfoCard
              label="Rate"
              value={`${offer?.rate || 0}%`}
            />
            <InfoCard
              label="Commission"
              value={`${offer?.commission || 0}%`}
            />
            <InfoCard
              label="Facultative Offer"
              value={`${offer?.facultative_offer || 0}%`}
            />
            <InfoCard
              label="Brokerage"
              value={`${offer?.brokerage || 0}%`}
            />
            <InfoCard
              label="Premium"
              value={`${offer?.offer_detail?.currency} ${numeral(offer?.premium).format("0,0.00")}`}
            />
            <InfoCard
              label="Sum Insured"
              value={`${offer?.offer_detail?.currency} ${numeral(offer?.sum_insured).format("0,0.00")}`}
            />
            <InfoCard
              label="Facultative Sum Insured"
              value={`${offer?.offer_detail?.currency} ${numeral(offer?.fac_sum_insured).format("0,0.00")}`}
            />
            <InfoCard
              label="Facultative Premium"
              value={`${offer?.offer_detail?.currency} ${numeral(offer?.fac_premium).format("0,0.00")}`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

const InfoCard = ({ label, value }: { label: string; value?: string | null }) => (
  <div className="bg-card-600/50 rounded-lg p-4 backdrop-blur-sm">
    <dt className="text-sm font-medium text-gray-400">{label}</dt>
    <dd className="mt-2 text-base font-semibold text-gray-100">
      {value || "N/A"}
    </dd>
  </div>
);
