import { FC, Fragment, useRef, PropsWithChildren } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { classNames, wrapClick } from "utils";
import Loader from "./loader";
import Shimmers from "components/shimmer";

interface ModalProps {
  title: string;
  description?: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  renderActions?: () => JSX.Element;
  hideActions?: boolean;
  hidePadding?: boolean;
  size?: "3xl" | "4xl" | "5xl" | "6xl" | "7xl";
  loading?: boolean;
}

const sizeMap = {
  "3xl": "max-w-3xl",
  "4xl": "max-w-4xl",
  "5xl": "max-w-5xl",
  "6xl": "max-w-6xl",
  "7xl": "max-w-7xl",
};

const Modal: FC<PropsWithChildren<ModalProps>> = ({
  open,
  setOpen,
  children,
  renderActions,
  title,
  description,
  hideActions,
  hidePadding,
  size = "3xl",
  loading,
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} appear={open} as={Fragment}>
      <Dialog
        as="div"
        className={classNames("theme", "fixed z-10 inset-0 overflow-y-auto")}
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-out duration-300"
            enterFrom="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
            enterTo="translate-y-0 opacity-100 sm:scale-100"
            leave="transform transition ease-in duration-200"
            leaveFrom="translate-y-0 opacity-100 sm:scale-100"
            leaveTo="translate-y-4 opacity-0 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                sizeMap[size],
                "inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full"
              )}
            >
              <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                <button
                  type="button"
                  className="bg-card-500  rounded-md text-gray-400  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 "
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              <div className="sm:flex bg-card-500 sm:flex-col">
                <div className="mx-auto flex-shrink-0 flex flex-col items-start rounded sm:mx-0 sm:px-6 sm:py-4 border-b border-gray-200">
                  {loading ? (
                    <div className="w-1/2">
                      <Shimmers.SingleShimmer />
                    </div>
                  ) : (
                    <Dialog.Title
                      as="h3"
                      className="flex-1 text-lg leading-6 font-medium text-gray-700 0 text-center sm:text-left"
                    >
                      {title}
                    </Dialog.Title>
                  )}
                  {loading ? (
                    <div className="w-1/2">
                      <Shimmers.DoubleShimmer />
                    </div>
                  ) : (
                    <>
                      {description && (
                        <p className="w-full mt-1 text-base  text-gray-400  sm:w-auto sm:text-sm">
                          {description}
                        </p>
                      )}
                    </>
                  )}

                </div>
                <div
                  className={classNames(
                    hideActions || hidePadding
                      ? "overflow-y-auto min-h-[50vh]"
                      : "sm:p-6 min-h-[50vh] overflow-y-auto",
                    loading ? "min-h-[50vh]" : "",
                    "flex flex-col flex-1 w-full max-h-[75vh]"
                  )}
                >
                  {loading ? <Loader /> : children}
                </div>
              </div>
              {!hideActions && !loading && (
                <div className="bg-gray-50 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
                  <>{renderActions?.()}</>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white  text-base font-medium text-gray-700  hover:bg-gray-50  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={wrapClick(() => setOpen(false))}
                    ref={cancelButtonRef}
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
