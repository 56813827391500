import { DocumentIcon } from '@heroicons/react/24/outline'
import React from 'react'

type Props = {
    notification: any
    parsedNotification: any
}

const EmailNotificationComponent = ({ notification, parsedNotification }: Props) => {
    return (
        <div className="max-w-5xl mx-auto px-8 py-6">
            <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-6 rounded-r">
                <p className="text-amber-800">
                    {JSON.parse(notification?.system_notification?.notification_content || "{}")?.message}
                </p>
            </div>

            <div className="bg-white shadow rounded-lg p-6 mb-6">
                <div className="space-y-4">
                    <div className="grid grid-cols-3 gap-4">
                        <div>
                            <p className="text-sm text-gray-500">Type of Email</p>
                            <p className="font-medium">{parsedNotification?.type}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Created On</p>
                            <p className="font-medium">
                                {new Date(notification?.system_notification?.created_at).toLocaleString()}
                            </p>
                        </div>
                    </div>

                    <div className="border-t border-gray-200 pt-4">
                        <div className="space-y-3">
                            <div>
                                <p className="text-sm text-gray-500">To</p>
                                <p className="font-medium">
                                    {parsedNotification?.recipient?.company_name || parsedNotification?.recipient?.re_company_name}{" "}
                                    <span className="text-gray-600">
                                        {`<${parsedNotification?.recipient.email || parsedNotification?.recipient?.associate_email?.join(", ")}>`}
                                    </span>
                                </p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-500">Cc</p>
                                <p className="font-medium">{parsedNotification?.other_recipients?.join(", ")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white shadow rounded-lg p-6 mb-6">
                <h3 className="text-lg font-medium mb-4">Message</h3>
                <div
                    className="prose max-w-none"
                    dangerouslySetInnerHTML={{ __html: parsedNotification?.message_content }}
                ></div>
            </div>

            {parsedNotification?.attachments && (
                <div className="bg-white shadow rounded-lg p-6">
                    <h3 className="text-lg font-medium mb-4">Attachments</h3>
                    <ul className="divide-y divide-gray-200">
                        {parsedNotification?.attachments?.map((file: any, key: number) => (
                            <li key={key} className="py-3 flex items-center justify-between">
                                <div className="flex items-center">
                                    <DocumentIcon className="h-5 w-5 text-gray-400 mr-3" />
                                    <span className="text-gray-900">{file.file_name}</span>
                                </div>
                                <a
                                    href={`${file.downloadblelink}`}
                                    target="_blank"
                                    className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                >
                                    Download
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    )
}

export default EmailNotificationComponent