import { PlusIcon } from "@heroicons/react/24/outline";
import {
  ActionButton,
  Avatar,
  OfficeButton,
  OfficeHeader,
  Paginator,
  TableComponent,
  TableLoader,
} from "components";
import config from "config";
import { useEmployees } from "hooks/data/use-employees";
import { FC, useEffect } from "react";
import { useSearch, useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useUrlState, wrapClick } from "utils";
import CreateEmployeeContainer from "./create";
import { Action } from "components/buttons/action-button";
import { Employees_rows } from "_graphql/queries/__generated__/Employees";
import ViewEmployeeContainer from "./view";
import RemoveEmployeeContainer from "./remove";
import ResetEmployeeCredentialsContainer from "./reset-credentials";
import UpdateEmployeeContainer from "./update";
import { usePagination } from "hooks";
import AssignCedantContainer from "./assign-cedant";

type Props = {};

const EmployeesPage: FC<Props> = () => {
  const [modal, setModal] = useUrlState("modal");
  const { offset, limit, search, setPage } = usePagination()
  const searchParams = useSearch<LocationGenerics>();
  const navigate = useNavigate<LocationGenerics>();
  const { employees, refetch, loading, length, } = useEmployees({ offset, limit, search });
  const dispatchAction =
    (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
      () => {
        navigate({
          search: (old) => ({
            ...old,
            id,
            modal: action,
          }),
        });
      };

  useEffect(() => {
    navigate({
      search: (old) => ({
        ...old,
        ...(!old?.page ? { page: config.constants.page } : {}),
        ...(!old?.pageSize ? { pageSize: config.constants.pageSize } : {}),
        ...(!old?.search ? { search: "" } : {}),
        ...(!old?.sort ? { sort: "code" } : {}),
        ...(!old?.viewType ? { viewType: "grid" } : {}),
      }),
    });
  }, [navigate]);



  return (
    <main className="flex-1 flex flex-col overflow-hidden h-screen bg-primary-500">
      <OfficeHeader
        renderActions={() => (
          <OfficeButton
            type="button"
            onClick={wrapClick(() => setModal("create"))}
            size="sm"
          >
            <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
            Add New Employee
          </OfficeButton>
        )}
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0 w-full py-4 sm:py-6 overflow-hidden overflow-y-auto light">
          <TableComponent
            title={"Employees"}
            hasSearch
            data={employees}
            defaultView="grid"
            loading={loading}
            refetch={refetch}
            pagination={() => <Paginator offset={offset} limit={limit} totalItems={length} currentSize={employees.length} setOffset={setPage} />}
            renderGridItem={(person: Employees_rows) => (
              <li
                key={person.employee_id}
                className="col-span-1 flex flex-col divide-y divide-card-600 rounded-lg bg-card-500 text-center shadow"
              >
                <div className="flex flex-1 flex-col p-8">
                  <div className="flex items-center justify-center">
                    <Avatar
                      size="xl"
                      alt={person.emp_abbrv?.split("").join(" ") || "N A"}
                    />
                  </div>
                  <h3 className="mt-6 text-sm font-medium text-gray-200">
                    {person.employee_first_name} {person.employee_last_name}
                  </h3>
                  <dl className="mt-1 flex flex-grow flex-col justify-between">
                    <dt className="sr-only">Title</dt>
                    <dd className="text-sm text-gray-500">
                      {person.employee_email}
                    </dd>
                  </dl>
                  <div className="mt-1 flex flex-col justify-between bg-primary-100 mx-auto px-3 rounded-3xl">
                    <span className="text-sm text-primary-500">
                      {person.user?.position}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-card-600">
                    <div className="flex w-0 flex-1">
                      <button className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                        <ActionButton
                          action="view"
                          tooltip="View Employee Details"
                          onClick={dispatchAction(person.employee_id, "view")}
                        />
                      </button>
                    </div>
                    <div className="-ml-px flex w-0 flex-1">
                      <button className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                        <ActionButton
                          action="reject"
                          tooltip="Reset Employee Credentials"
                          onClick={dispatchAction(person.employee_id, "reject")}
                        />
                      </button>
                    </div>
                    <div className="-ml-px flex w-0 flex-1">
                      <button className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                        <ActionButton
                          action="update"
                          tooltip="Update Employee Details"
                          onClick={dispatchAction(person.employee_id, "update")}
                        />
                      </button>
                    </div>
                    <div className="-ml-px flex w-0 flex-1">
                      <button className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                        <ActionButton
                          action="assign"
                          tooltip="Assign Cedant"
                          onClick={dispatchAction(person.employee_id, "assign")}
                        />
                      </button>
                    </div>
                    <div className="-ml-px flex w-0 flex-1">
                      <button className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900">
                        <ActionButton
                          action="remove"
                          onClick={dispatchAction(person.employee_id, "remove")}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            )}
            columns={[
              {
                accessor: "employee_first_name",
                name: "First Name",
              },
              {
                accessor: "employee_last_name",
                name: "Last Name",
              },
              {
                accessor: "employee_email",
                name: "Email",
              },
              {
                accessor: "employee_phonenumber",
                name: "Phone",
              },
              {
                accessor: "user.position",
                name: "Department",
              },
              {
                accessor: "",
                name: "Actions",
                render: (person: Employees_rows) => (
                  <>
                    <ActionButton
                      action="view"
                      tooltip="View Employee Details"
                      onClick={dispatchAction(person.employee_id, "view")}
                    />
                    <ActionButton
                      action="reject"
                      tooltip="Reset Employee Credentials"
                      onClick={dispatchAction(person.employee_id, "reject")}
                    />
                    <ActionButton
                      action="update"
                      tooltip="Update Employee Details"
                      onClick={dispatchAction(person.employee_id, "update")}
                    />
                    <ActionButton
                      action="assign"
                      tooltip="Assign Cedant"
                      onClick={dispatchAction(person.employee_id, "assign")}
                    />
                    <ActionButton
                      action="remove"
                      onClick={dispatchAction(person.employee_id, "remove")}
                    />
                  </>
                )
              },
            ]}
            renderLoader={() => <TableLoader items={[1, 1, 1, 1, 1]}

            />}
          />
        </div>
      </div>
      <CreateEmployeeContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
        refetch={refetch}
      />

      {searchParams.id && (
        <>
          <ViewEmployeeContainer
            open={modal === "view"}
            setOpen={(val: boolean) => setModal(val ? "view" : undefined)}
          />
          <RemoveEmployeeContainer
            open={modal === "remove"}
            setOpen={(val: boolean) => setModal(val ? "remove" : undefined)}
          />
          <ResetEmployeeCredentialsContainer
            open={modal === "reject"}
            setOpen={(val: boolean) => setModal(val ? "reject" : undefined)}
          />
          <UpdateEmployeeContainer
            open={modal === "update"}
            setOpen={(val: boolean) => setModal(val ? "update" : undefined)}
            refetch={refetch}
          />
          <AssignCedantContainer
            open={modal === "assign"}
            setOpen={(val: boolean) => setModal(val ? "assign" : undefined)}
            refetch={refetch}
          />
        </>
      )}
    </main>
  );
};

export default EmployeesPage;
