/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ApprovalStatus {
  ACTIVE = "ACTIVE",
  DEACTIVATE = "DEACTIVATE",
  PENDING = "PENDING",
  PROBATION = "PROBATION",
}

export enum BrokerApprovalState {
  ACTIVE = "ACTIVE",
  DEACTIVATE = "DEACTIVATE",
  PROBATION = "PROBATION",
}

export enum Claim_status {
  CLAIMED = "CLAIMED",
  UNCLAIMED = "UNCLAIMED",
}

export enum Offer_status {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
  PENDING = "PENDING",
}

export enum Payment_status {
  PAID = "PAID",
  PARTPAYMENT = "PARTPAYMENT",
  UNPAID = "UNPAID",
}

export enum TransactionStatus {
  APPROVED = "APPROVED",
  INITIATED = "INITIATED",
  MODIFY = "MODIFY",
  REJECTED = "REJECTED",
}

export interface OfferFilters {
  page?: number | null;
  limit?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
