import { useAppLocation, useAppNavigate, useAppSearch } from '_graphql/cache/auth'
import { GetTreaties_rows } from '_graphql/queries/__generated__/GetTreaties'
import { ActionButton, CalendarHeader, Paginator, RegularInsurerPicker, TableComponent } from 'components'
import { Action } from 'components/buttons/action-button'
import { usePagination } from 'hooks'
import { FC } from 'react'
import CreateTreatyClaimContainer from './create'
import { GetTreatyClaims_getTreatyClaims } from '_graphql/queries/__generated__/GetTreatyClaims'
import moment from 'moment'
import { useReceivablePayments } from 'hooks/data/use-treaties'

type Props = {}

const TreatyClaimPaymentsPage: FC<Props> = (props) => {
    const navigate = useAppNavigate();
    const searchParams = useAppSearch()
    const { params } = useAppLocation()
    const { offset, limit, setPage } = usePagination()
    const { payments, length, loading, refetch } = useReceivablePayments({
        treatyId: params?.treaty || "",
        limit: limit,
        offset: offset
    })


    const navigateItem =
        (id: string, layers: string, treaty_type: string) => () => {
            navigate({
                to: `./${id}/view-claims`,
                search: (old) => ({
                    ...old,
                    // layers: layers,
                    layer: "1",
                    treaty_type: treaty_type,
                }),
            });
        };

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };

    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <CalendarHeader />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <TableComponent
                        loading={false}
                        title={'treaty claims payments'}
                        hasSearch
                        columns={[
                            {
                                name: "Policy #",
                                accessor: "treaty_reference",
                                render: (value: GetTreatyClaims_getTreatyClaims) => (
                                    <div className="flex flex-col leading-0">
                                        <span className='font-medium text-gray-200'>{value?.policy_number}</span>

                                    </div>
                                ),
                            },
                            {
                                name: "Claim #",
                                accessor: "insurer.insurer_company_name",
                                render: (value: GetTreatyClaims_getTreatyClaims) => (
                                    <div className="flex flex-col leading-0">
                                        <span className='font-medium text-gray-200'>{value?.claim_number}</span>
                                        {/* <span>{moment(value?.claim_date).format("LL")}</span> */}
                                    </div>

                                )
                            },
                            {
                                name: "Insured",
                                accessor: "employee.employee_first_name",
                                render: (value: GetTreatyClaims_getTreatyClaims) => (
                                    <div className="flex flex-row leading-0">
                                        <span className='font-medium text-gray-200'>
                                            {value?.insured_name || "N/A"}{" "}
                                        </span>
                                    </div>
                                ),
                            },
                            {
                                name: "Date of Loss",
                                accessor: "treaty_deduction",
                                render: (value: GetTreatyClaims_getTreatyClaims) => (
                                    <div className="flex leading-0">
                                        <span>
                                            {moment(value?.date_of_loss).format("LL")}
                                        </span>
                                    </div>
                                ),
                            },
                            {
                                name: "Claim Paid",
                                accessor: "claim_paid",
                            },
                            {
                                name: "Actions",
                                accessor: "actions",
                                render: (value: GetTreaties_rows) => (
                                    <>
                                        <ActionButton
                                            action={"view"}
                                            tooltip="View Claim"
                                            onClick={dispatchAction(value?.treaty_id || "", "create")}
                                        />
                                        <ActionButton
                                            action={"update"}
                                            tooltip="Update Claim"
                                            onClick={dispatchAction(value?.treaty_id || "", "update")}
                                        />
                                        <ActionButton
                                            action={"remove"}
                                            tooltip="Remove Claim"
                                            onClick={dispatchAction(value?.treaty_id || "", "update")}
                                        />
                                        <ActionButton
                                            action={"expand"}
                                            tooltip="View Claim Payments"
                                            onClick={navigateItem(
                                                value?.treaty_id || "",
                                                value?.layer_limit as string,
                                                value?.treaty_program?.treaty_type as string
                                            )}
                                        />
                                    </>
                                ),
                            },
                        ]}
                        data={payments}
                        refetch={refetch}
                        renderHeaderItems={() => (
                            <>
                                <RegularInsurerPicker id={'insurer'} label={''} rawId values={{ insurer: searchParams?.insurer }} setFieldValue={(e: any, value: any) => {
                                    console.log('setFieldValue', e, value)
                                }} setFieldTouched={() => { }} setFieldError={() => { }} />
                            </>
                        )}
                        pagination={() => <Paginator offset={offset} limit={limit} totalItems={length} currentSize={payments.length} setOffset={setPage} />}
                    />
                </div>
            </div>
            <CreateTreatyClaimContainer open={searchParams?.modal === 'create'} setOpen={(val) => navigate({
                search(prev) {
                    return {
                        ...prev,
                        modal: val ? 'create' : undefined
                    }
                },
            })} />
        </main>
    )
}

export default TreatyClaimPaymentsPage 