import { FC } from "react";

interface TableGridBodyComponentProps<TData = any> {
  data: TData[];
  renderItem?: FC<TData>;
  renderLoader?: FC;
  loading?: boolean;
}

const TableGridBodyComponent: FC<TableGridBodyComponentProps> = ({
  data,
  renderItem,
  loading,
  renderLoader,
}) => {
  return (
    <div className="overflow-x-auto sm:-mx-6 lg:-mx-0 no-scrollbar h-full">
      <div className="p-3 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 auto-rows-min content-start">
        {loading
          ?
          <div className="lg:col-span-3 xl:col-span-4 col-span-1 sm:col-span-2">
            {renderLoader?.({})}
          </div>
          ?? (
            <>
              <div className="flex space-x-3 items-center h-56 bg-gray-200 animate-pulse rounded-md"></div>
              <div className="flex space-x-3 items-center h-56 bg-gray-200 animate-pulse rounded-md"></div>
              <div className="flex space-x-3 items-center h-56 bg-gray-200 animate-pulse rounded-md"></div>
              <div className="flex space-x-3 items-center h-56 bg-gray-200 animate-pulse rounded-md"></div>
            </>
          )
          : data?.map((item) => renderItem?.(item))}
      </div>
    </div>
  );
};

export default TableGridBodyComponent;
