import React from 'react'
import { Link } from 'react-location'

type Props = {
    notification: any
    parsedNotification: any
}

const ReinsurerNotificationComponent = ({ notification, parsedNotification }: Props) => {
    if (parsedNotification.hasOwnProperty("rep_email")) {
        return (
            <div className="max-w-4xl mx-auto p-6">
                <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-6 rounded-r shadow-sm">
                    {JSON.parse(notification?.system_notification?.notification_content || "{}").message}
                </div>
                <div className="bg-white rounded-lg shadow-md p-6 mb-6">
                    <h2 className="text-xl font-semibold mb-4">Associate Details</h2>
                    <div className="grid grid-cols-2 gap-6">
                        <div>
                            <p className="text-gray-600 text-sm mb-1">Associate Name</p>
                            <p className="font-medium">
                                {parsedNotification?.rep_first_name} {parsedNotification?.rep_last_name}
                            </p>
                        </div>
                        <div>
                            <p className="text-gray-600 text-sm mb-1">Position</p>
                            <p className="font-medium">{parsedNotification?.position}</p>
                        </div>
                        <div>
                            <p className="text-gray-600 text-sm mb-1">Email</p>
                            <p className="font-medium">{parsedNotification?.rep_email}</p>
                        </div>
                        <div>
                            <p className="text-gray-600 text-sm mb-1">Phone Numbers</p>
                            <p className="font-medium">
                                {parsedNotification?.rep_primary_phonenumber}
                                {parsedNotification?.rep_secondary_phonenumber && (
                                    <span className="text-gray-500"> / {parsedNotification?.rep_secondary_phonenumber}</span>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
                <Link
                    className="inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-colors shadow-sm"
                >
                    <span>View Reinsurer Details</span>
                </Link>
            </div>
        );
    } else if (parsedNotification.hasOwnProperty("re_company_email")) {
        return (
            <div className="max-w-4xl mx-auto p-6">
                <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-6 rounded-r shadow-sm">
                    {JSON.parse(notification?.system_notification?.notification_content || "{}").message}
                </div>
                <div className="flex justify-center mb-6">
                    <div className="w-16 h-16 rounded-full bg-blue-100 text-blue-600 flex items-center justify-center font-bold text-xl">
                        {parsedNotification?.re_abbrv}
                    </div>
                </div>
                <div className="bg-white rounded-lg shadow-md p-6 mb-6">
                    <h2 className="text-xl font-semibold mb-4">Company Details</h2>
                    <div className="grid grid-cols-2 gap-6">
                        <div>
                            <p className="text-gray-600 text-sm mb-1">Company Name</p>
                            <p className="font-medium">{parsedNotification?.re_company_name}</p>
                        </div>
                        <div>
                            <p className="text-gray-600 text-sm mb-1">Website</p>
                            <p className="font-medium">{parsedNotification?.re_company_website}</p>
                        </div>
                        <div>
                            <p className="text-gray-600 text-sm mb-1">Email</p>
                            <p className="font-medium">{parsedNotification?.re_company_email}</p>
                        </div>
                        <div>
                            <p className="text-gray-600 text-sm mb-1">Address</p>
                            <p className="font-medium">
                                {parsedNotification?.address?.suburb} - {parsedNotification?.address?.region},{" "}
                                {parsedNotification?.address?.country}
                            </p>
                        </div>
                    </div>
                </div>
                <Link
                    to={`/re-insurers/${parsedNotification?.reinsurer_id}`}
                    className="inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-md transition-colors shadow-sm"
                >
                    <span>View Reinsurer Details</span>
                </Link>
            </div>
        );
    } else {
        return (
            <div className="max-w-4xl mx-auto p-6">
                <div className="bg-amber-50 border-l-4 border-amber-400 p-4 rounded-r shadow-sm">
                    {JSON.parse(notification?.system_notification?.notification_content || "{}").message}
                </div>
            </div>
        );
    }
}

export default ReinsurerNotificationComponent