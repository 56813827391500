import { FC } from "react";
import {
  EyeIcon,
  PencilSquareIcon,
  DocumentDuplicateIcon,
  CalculatorIcon,
  ArrowsPointingOutIcon,
  PaperAirplaneIcon,
  CalendarDaysIcon,
  UserPlusIcon,
  UserIcon,
  ClipboardDocumentListIcon,
  ClipboardDocumentCheckIcon,
  ArrowPathRoundedSquareIcon,
  MagnifyingGlassPlusIcon,
  ArrowTopRightOnSquareIcon,
  Cog8ToothIcon,
  TrashIcon,
  ReceiptPercentIcon,
  XMarkIcon,
  BellAlertIcon,
  InboxStackIcon,
  ArrowUpIcon,
  ArrowDownIcon,
  UsersIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/24/outline";
import { classNames, wrapClick } from "utils";
import "react-tooltip/dist/react-tooltip.css";
import { ModalActions } from "utils/types";

// replace actions from modal actions to actions
// const Actions = ;

export type Action = ModalActions;
const ActionIcons: { [key in Action]: typeof EyeIcon } = {
  approve: ClipboardDocumentCheckIcon,
  assign: UserPlusIcon,
  calculate: CalculatorIcon,
  create: UserPlusIcon,
  chat: ChatBubbleBottomCenterTextIcon,
  configure: Cog8ToothIcon,
  distribute: InboxStackIcon,
  reassign: UserIcon,
  reject: ArrowPathRoundedSquareIcon,
  resolve: ClipboardDocumentListIcon,
  schedule: CalendarDaysIcon,
  send: PaperAirplaneIcon,
  update: PencilSquareIcon,
  view: EyeIcon,
  expand: ArrowsPointingOutIcon,
  goto: ArrowTopRightOnSquareIcon,
  clone: DocumentDuplicateIcon,
  investigate: MagnifyingGlassPlusIcon,
  remove: TrashIcon,
  assignPercentage: ReceiptPercentIcon,
  close: XMarkIcon,
  alert: BellAlertIcon,
  "update-layer": PencilSquareIcon,
  "send-layer": PaperAirplaneIcon,
  "view-layer": EyeIcon,
  export: ArrowDownIcon,
  import: ArrowUpIcon,
  "view-associate": EyeIcon,
  "update-associate": PencilSquareIcon,
  "remove-associate": TrashIcon,
  "participants": UsersIcon,
};

interface ActionButtonProps {
  action: Action;
  onClick: (...val: any) => any;
  disabled?: boolean;
  tooltip?: string;
  extraClassName?: string
}

const ActionButton: FC<ActionButtonProps> = ({
  action,
  onClick,
  tooltip,
  extraClassName = "",
  disabled = false,
}) => {
  const Icon = ActionIcons[action];

  return (
    <button
      data-tooltip-delay-show={1000}
      data-tooltip-id="global-tooltip"
      data-tooltip-content={tooltip || action}
      type="button"
      onClick={wrapClick(onClick)}
      disabled={disabled}
      className={classNames(
        disabled
          ? "cursor-not-allowed text-gray-500 hover:bg-gray-300"
          : "text-gray-500 hover:bg-gray-300 hover:text-gray-900",
        "inline-flex items-center rounded-full border border-transparent p-1  focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2",
        extraClassName
      )}
    >
      <Icon className="h-5 w-5" aria-hidden="true" />
    </button>
  );
};
export default ActionButton;
