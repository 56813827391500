import { gql } from "@apollo/client";


export const approveUnderwriter = gql`
mutation BrokerApproval(
  $associateId: ID!
  $approvalState: BrokerApprovalState!
) {
  brokerApproval(associate_id: $associateId, approval_state: $approvalState) {
    message
  }
}
`

export const acceptUnderwriterOffer = gql`
mutation AcceptOffer($employeeId: ID, $offerId: ID) {
  acceptOffer(employee_id: $employeeId, offer_id: $offerId) {
    message
    status
  }
}
`

export const rejectUnderwriterOffer = gql`
mutation DeclineOffer($employeeId: ID, $offerId: ID) {
  declineOffer(employee_id: $employeeId, offer_id: $offerId) {
    status
    message
  }
}
`

export const sendPlaceItOfferMessage = gql`
mutation SendTheadMessage($offerId: ID!, $message: String, $brokerFirstname: String, $brokerLastname: String) {
  sendTheadMessage(offer_id: $offerId, message: $message, broker_firstname: $brokerFirstname, broker_lastname: $brokerLastname) {
    message
  }
}
`;

export const archiveMessageThread = gql`
mutation ArchiveMessageThread($offerID: ID!, $email: String) {
  archiveMessageThread(offer_id: $offerID, email: $email)
}
`;