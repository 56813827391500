import { gql } from "@apollo/client";


export const getTreatyClaims = gql`
query GetTreatyClaims(
  $treatyId: ID
  $insurerId: ID
  $limit: Int
  $offset: Int
) {
  getTreatyClaimsCount(treaty_id: $treatyId)
  getTreatyClaims(
    treaty_id: $treatyId
    insurer_id: $insurerId
    limit: $limit
    offset: $offset
  ) {
    treaty_claim_id
    policy_number
    claim_number
    insured_name
    date_of_loss
    claim_date
    claim_paid
    layer_number
    expected_deductible
    treaty_comment
    treaty_claim_details
    reinstatement_premium
    treaty {
      treaty_id
      treaty_reference
      kek_reference
      treaty_details
      currency
      order_hereon
      treaty_payment_status
      layer_limit
      treaty_participants {
        treaty_participation_id
        treaty_participation_percentage
        layer_number
        reinsurer {
          re_company_email
          re_abbrv
          re_company_name
        }
      }
    }
    receivable_payments {
      receivable_payment_id
      uuid
      payment_amount
      payment_detail
      created_at
    }
  }
}

`;

export const getTreatyClaim = gql`
query GetTreatyClaim($treatyClaimId: ID) {
  getTreatyClaim(treaty_claim_id: $treatyClaimId) {
    treaty_claim_id
    policy_number
    claim_number
    insured_name
    date_of_loss
    claim_date
    claim_paid
    layer_number
    expected_deductible
    treaty_comment
    treaty_claim_details
    reinstatement_premium
    treaty {
      treaty_id
      treaty_reference
      kek_reference
      treaty_details
      currency
      order_hereon
      treaty_payment_status
      layer_limit
      treaty_program {
        insurer {
          insurer_company_name
        }
        treaty_name
        treaty_type
        treaty_details
      }
    }
  }
}
`;

