import React from 'react'

type Props = {
    notification: any
    parsedNotification: any
}

const BusinessNotificationComponent = ({ notification, parsedNotification }: Props) => {
    return (
        <div className="max-w-5xl mx-auto px-8 py-6">
            <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-6 rounded-r">
                <p className="text-amber-800">
                    {JSON.parse(notification?.system_notification?.notification_content || "{}")?.message}
                </p>
            </div>

            <div className="bg-white shadow rounded-lg p-6 mb-6">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="text-lg font-medium">Business Details</h2>
                </div>

                <div className="grid grid-cols-2 gap-6 mb-6">
                    <div>
                        <p className="text-sm text-gray-500">Business Name</p>
                        <p className="font-medium">{parsedNotification?.business_name}</p>
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">Created On</p>
                        <p className="font-medium">{new Date(notification?.system_notification?.created_at).toDateString()}</p>
                    </div>
                </div>

                <div className="border-t border-gray-200 pt-6">
                    <h3 className="text-lg font-medium mb-4">Business Class Details</h3>
                    <div className="overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead>
                                <tr>
                                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Detail
                                    </th>
                                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Type
                                    </th>
                                    <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Required
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {JSON.parse(parsedNotification?.business_details)?.map((step: any, idx: string) => (
                                    <tr key={idx} className="hover:bg-gray-50">
                                        <td className="px-4 py-3 text-sm text-gray-900">
                                            {step?.keydetail}
                                        </td>
                                        <td className="px-4 py-3 text-sm text-gray-600">
                                            {step?.type || "Text"}
                                        </td>
                                        <td className="px-4 py-3 text-sm">
                                            <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                                                step?.required === "Yes" 
                                                    ? "bg-green-100 text-green-800"
                                                    : "bg-gray-100 text-gray-800"
                                            }`}>
                                                {step?.required || "Yes"}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BusinessNotificationComponent