import React from 'react'

type Props = {
    notification: any
    parsedNotification: any
}

const DocumentSMSNotificationComponent = ({ notification, parsedNotification }: Props) => {
    return (
        <div className="max-w-4xl mx-auto px-6 py-8">
            <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-6 rounded-r shadow-sm">
                <p className="text-amber-800">
                    {JSON.parse(notification?.system_notification?.notification_content || "{}").message}
                </p>
            </div>

            <div className="bg-white rounded-lg shadow-md p-6">
                <div className="flex items-center space-x-3 mb-4">
                    <div className="h-10 w-10 rounded-full bg-emerald-100 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-emerald-600" viewBox="0 0 20 20" fill="currentColor">
                            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
                        </svg>
                    </div>
                    <h2 className="text-lg font-medium text-gray-900">SMS Notification</h2>
                </div>

                <div className="text-gray-600">
                    {parsedNotification?.message || "No additional details available"}
                </div>
            </div>
        </div>
    )
}

export default DocumentSMSNotificationComponent