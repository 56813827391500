import { useQuery } from "@apollo/client";
import { placeItClient } from "_graphql";
import { BrokerOffers, BrokerOffersVariables } from "_graphql/queries/__generated__/BrokerOffers";
import { Offer_messagesVariables } from "_graphql/queries/__generated__/Offer_messages";
import { Offer_messages } from "_graphql/queries/__generated__/Offer_messages";
import { getPlaceItOfferMessages, getPlaceItOffers } from "_graphql/queries/offers.place-it";


export const usePlaceItOffers = (variables: BrokerOffersVariables) => {
    const { data, ...rest } = useQuery<BrokerOffers, BrokerOffersVariables>(getPlaceItOffers, {
        variables,
        client: placeItClient,
        fetchPolicy: "network-only"
    });

    return { offers: data?.brokerOffers?.data || [], length: data?.brokerOffers?.paginatorInfo?.total || 0, ...rest };
}

export const usePlaceItOfferMessages = (variables: Offer_messagesVariables) => {
    const { data, ...rest } = useQuery<Offer_messages, Offer_messagesVariables>(getPlaceItOfferMessages, {
        variables,
        client: placeItClient,
        fetchPolicy: "network-only"
    });

    console.log(data)

    return { offers: data?.offer_messages || [], ...rest };
}