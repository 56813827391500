import { gql } from "@apollo/client";



export const getPlaceItOffers = gql`
query BrokerOffers($brokerId: ID, $filters: OfferFilters) {
  brokerOffers(brokerID: $brokerId, filters: $filters) {
    data {
      id
      brokersbroker_id
      export_id
      rate
      commission
      commission_amount
      facultative_offer
      sum_insured
      fac_sum_insured
      premium
      fac_premium
      offer_status
      payment_status
      claim_status
      transaction_status
      created_at
      updated_at
      closed_date
      points_earned
      doc_path
      reviews
      offer_detail {
        id
        offersoffer_id
        export_fk_id
        policy_number
        insured_by
        period_of_insurance_from
        period_of_insurance_to
        currency
        offer_comment
        offer_details
        updated_at
      }
      class_of_business {
        id
        export_id
        business_name
        business_details
      }
      insurer {
        id
        export_id
        insurer_company_name
        insurer_company_email
        insurer_company_website
        insurer_abbrv
      }
      insurer_associate {
        assoc_first_name
        assoc_email
        assoc_last_name
      }
    }
    paginatorInfo {
      total
      currentPage
      perPage
    }
  }
}
`;


export const getPlaceItOfferMessages = gql`
query Offer_messages($offerMessagesId: ID!) {
  offer_messages(id: $offerMessagesId) {
    uuid
    sender_name
    state
    timestamp
    message
  }
}
`;
