import { OfficeHeader, Tabs } from 'components'
import { FC } from 'react'
import { Link } from 'react-location'
import { classNames, useUrlState } from 'utils'
import { BrokerageStatementTab, ReportingTab } from './tabs'
import SOAReportTab from './tabs/soa-report'
import MonthlyReportTab from './tabs/monthly-report'

type Props = {}

const tabs = [{ name: "Brokerage Statement ", href: "brokerageStatement" }, { name: "SOA Reports ", href: "soa_reports" }, { name: "Monthly Reports ", href: "monthly_reports" }];


const ReportsPage: FC<Props> = (props) => {
    const [activeTab] = useUrlState("orderStatus");

    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <OfficeHeader />
            <Tabs tabs={tabs} orderStatus={activeTab} defaultTab={"Reporting"} />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  p-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    {!activeTab && <ReportingTab />}
                    {activeTab === "brokerageStatement" && (
                        <BrokerageStatementTab />
                    )}
                    {activeTab === "soa_reports" && (
                        <SOAReportTab />
                    )}
                    {activeTab === "monthly_reports" && (
                        <MonthlyReportTab />
                    )}
                </div>
            </div>
        </main>
    )
}

export default ReportsPage