import { useQuery } from "@apollo/client";
import { GetTreatyClaimVariables, GetTreatyClaim } from "_graphql/queries/__generated__/GetTreatyClaim";
import { GetTreatyClaims, GetTreatyClaimsVariables } from "_graphql/queries/__generated__/GetTreatyClaims";
import { getTreatyClaim, getTreatyClaims } from "_graphql/queries/treaty-claims";


export const useTreatyClaims = (variables?: GetTreatyClaimsVariables) => {
    const { data, ...rest } = useQuery<GetTreatyClaims, GetTreatyClaimsVariables>(getTreatyClaims, {
        variables,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });

    return {
        claims: data?.getTreatyClaims || [],
        count: data?.getTreatyClaimsCount || 0,
        ...rest,
    }
}

export const useTreatyClaim = (variables?: GetTreatyClaimVariables) => {
    const { data, ...rest } = useQuery<GetTreatyClaim, GetTreatyClaimVariables>(getTreatyClaim, {
        variables,
        fetchPolicy: "network-only",
        notifyOnNetworkStatusChange: true,
    });

    return {
        claim: data?.getTreatyClaim,
        ...rest,
    }
}