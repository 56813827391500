import { List, Show } from "components/core";
import { Shimmers } from "components";
import { FC } from "react";


type Props = {
    items: number[];
    actionsCount?: number;
    showAction?: boolean;
};

const TableLoader: FC<Props> = ({
    items,
    actionsCount = 0,
    showAction = false,
}) => {
    return (
        <tr>
            <List
                data={items}
                renderItem={(count) => (
                    <td className="px-2 py-4 border-b border-shade-500" style={{ width: `${100 / items.length}%` }}>
                        <div className="w-full">
                            {count === 3 ? (
                                <Shimmers.AvatarShimmer />
                            ) : count === 2 ? (
                                <Shimmers.DoubleShimmer />
                            ) : (
                                <Shimmers.SingleShimmer />
                            )}
                        </div>
                    </td>
                )}
            />
            <Show if={showAction}>
                <td className="px-2 py-4 border-b border-shade-500" style={{ width: `${100 / (items.length + 1)}%` }}>
                    <div className="w-full">
                        <Shimmers.ActionsShimmer actionsCount={actionsCount} />
                    </div>
                </td>
            </Show>
        </tr>
    );
};

export default TableLoader;
