import { useMutation } from "@apollo/client";
import { useFormik } from "formik";
import { Modal, OfficeButton } from "components";
import { useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useAppSearch } from "_graphql/cache/auth";
import toast from "react-hot-toast";
import { BrokerApproval, BrokerApprovalVariables } from "_graphql/mutation/__generated__/BrokerApproval";
import { approveUnderwriter } from "_graphql/mutation/underwriter.place-it";
import { BrokerApproval as BrokerApprovalForm } from "components/forms";
import { placeItClient } from "_graphql";


export default function ApproveContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useAppSearch()
    const navigate = useNavigate<LocationGenerics>();
    const [execute, { loading }] = useMutation<
        BrokerApproval,
        BrokerApprovalVariables
    >(approveUnderwriter, {
        client: placeItClient
    });

    const form = useFormik<any>({
        initialValues: {
            approval_state: "",
        },
        onSubmit: async (values, helpers) => {
            execute({
                variables: {
                    approvalState: values.approval_state,
                    associateId: searchParams?.id || ""
                }
            }).then(({ data }) => {
                if (data?.brokerApproval?.message) {
                    helpers.resetForm();
                    toast.success(data?.brokerApproval?.message || "Underwriter approved successfully");
                    navigate({
                        search(prev) {
                            return {
                                ...prev,
                                id: undefined,
                                modal: undefined,
                            };
                        },
                    });
                }
            }).catch((err) => {
                console.log(err);
                toast.error("Could not approve underwriter");
            });
        },
        onReset: () => {
            setOpen(false);
            navigate({
                search(prev) {
                    return {
                        ...prev,
                        id: undefined,
                        modal: undefined,
                    };
                },
            });
        },
    });





    return (
        <Modal
            open={open}
            setOpen={() => {
                setOpen(false);
                navigate({
                    search(prev) {
                        return {
                            ...prev,
                            rate: undefined,
                            modal: undefined,
                        };
                    },
                });
            }}
            title="Approve Underwriter"
            // loading={fetchingClaim}
            description="Approve Underwriter"
            renderActions={() => (
                <div className="flex flex-row justify-end">
                    <OfficeButton
                        onClick={() => {
                            form.submitForm();
                        }}
                    >
                        {loading ? "Please wait ..." : "Approve"}
                    </OfficeButton>
                </div>
            )}
        >
            {/* {JSON.stringify(form.values, null, 2)} */}
            <BrokerApprovalForm form={form} />
        </Modal>
    );
}



