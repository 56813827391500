import { DocumentPlusIcon } from '@heroicons/react/24/outline'
import { useAppNavigate, useAppSearch } from '_graphql/cache/auth'
import { GetUnderwriters_underwriters, GetUnderwriters_underwriters_data } from '_graphql/queries/__generated__/GetUnderwriters'
import { ActionButton, OfficeButton, OfficeHeader, Paginator, TableComponent } from 'components'
import { useUnderWriters } from 'hooks/data/useUnderWriters'
import React, { FC } from 'react'
import { useUrlState } from 'utils'
import ApproveContainer from './approve'
import { Action } from 'components/buttons/action-button'
import clsx from 'clsx'
import { ApprovalStatus } from '_graphql/__generated__/globalTypes-place-it'
import _ from 'lodash'

type Props = {}

const UnderwritersPage: FC<Props> = () => {
    const [modal, setModal] = useUrlState("modal");
    const searchParams = useAppSearch();
    const navigate = useAppNavigate();
    const { underwriters, loading, refetch } = useUnderWriters({
        q: "ACTIVE",
        p: "ACTIVE",
        filters: {
            page: 1,
            limit: 10
        }
    });

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };

    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <OfficeHeader />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <TableComponent
                        title={"Underwriters"}
                        data={underwriters}
                        columns={[
                            {
                                accessor: "assoc_first_name",
                                name: "First Name",
                                render: (row: GetUnderwriters_underwriters_data) => _.startCase(row.assoc_first_name)
                            },
                            {
                                accessor: "assoc_last_name",
                                name: "Last Name",
                                render: (row: GetUnderwriters_underwriters_data) => _.startCase(row.assoc_last_name)
                            },
                            {
                                accessor: "assoc_primary_phonenumber",
                                name: "Primary Phone",
                                render: (row: GetUnderwriters_underwriters_data) => row.assoc_primary_phonenumber
                            },
                            {
                                accessor: "assoc_email",
                                name: "Email",
                                render: (row: GetUnderwriters_underwriters_data) => row.assoc_email
                            },
                            {
                                accessor: "position",
                                name: "Position",
                                render: (row: GetUnderwriters_underwriters_data) => row.position
                            },
                            {
                                accessor: "account_state_broker_approval",
                                name: "Broker Approval Status",
                                render: (row: GetUnderwriters_underwriters_data) => (
                                    <div className="flex">
                                        <div className={clsx("p-0.5 rounded-full", {
                                            "bg-green-600/30 text-green-600": row.account_state_broker_approval === ApprovalStatus.ACTIVE,
                                            "bg-red-600/30 text-red-600": row.account_state_broker_approval === ApprovalStatus.DEACTIVATE,
                                            "bg-yellow-600/30 text-yellow-600": row.account_state_broker_approval === ApprovalStatus.PENDING,
                                            "bg-gray-50 text-gray-600": row.account_state_broker_approval === ApprovalStatus.PROBATION,
                                        }, "text-xs px-5 font-medium")}>
                                            <span>{row.account_state_manager_approval}</span>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                accessor: "account_state_manager_approval",
                                name: "Mgr Approval Status",
                                render: (row: GetUnderwriters_underwriters_data) => (
                                    <div className="flex">
                                        <div className={clsx("p-0.5 rounded-full", {
                                            "bg-green-600/30 text-green-600": row.account_state_broker_approval === ApprovalStatus.ACTIVE,
                                            "bg-red-600/30 text-red-600": row.account_state_broker_approval === ApprovalStatus.DEACTIVATE,
                                            "bg-yellow-600/30 text-yellow-600": row.account_state_broker_approval === ApprovalStatus.PENDING,
                                            "bg-gray-50 text-gray-600": row.account_state_broker_approval === ApprovalStatus.PROBATION,
                                        }, "text-xs px-5 font-medium")}>
                                            <span>{row.account_state_manager_approval}</span>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                accessor: "assoc_phone",
                                name: "Actions",
                                render: (row: GetUnderwriters_underwriters_data) => (
                                    <div>
                                        {row.position !== "Manager" && <ActionButton action='approve' tooltip='Broker Approval' onClick={dispatchAction(row.id, "approve")} />}
                                    </div>
                                )
                            }
                        ]}
                        refetch={refetch}
                        loading={loading}
                        hasSearch
                        pagination={() => <Paginator offset={0} limit={0} totalItems={0} currentSize={0} setOffset={function (value: any): void {
                            throw new Error('Function not implemented.')
                        }} />}
                    />
                </div>
            </div>
            {searchParams?.id && searchParams?.modal === "approve" && (
                <ApproveContainer
                    open={searchParams?.modal === "approve"}
                    setOpen={(value) => navigate({
                        search(prev) {
                            return {
                                ...prev,
                                modal: value ? "approve" : undefined,
                                id: value ? searchParams?.id : undefined
                            }
                        },
                    })}
                />
            )}
        </main>
    )
}

export default UnderwritersPage