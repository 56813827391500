

import _ from 'lodash'
import numeral from 'numeral'
import React from 'react'

type Props = {
    data: any
}

const TreatySearchFormSummary = ({ data }: Props) => {
    return (
        <div className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
            {/* {JSON.stringify(_.omit(data, ["selectedTreaty.treaty_details"]), null, 2)} */}
            <div>
                <div className="grid grid-cols-3 gap-6">
                    <div className="">
                        <span className="block text-sm font-light text-gray-700">Treaty Ref#</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.selectedTreaty.treaty_reference || "N/A"}
                        </div>
                    </div>
                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">Treaty Program</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.selectedTreaty?.treaty_deduction?.treaty_program?.treaty_name || "N/A"}
                        </div>
                    </div>
                    <div className="col-span-4">
                        <span className="block text-sm font-light text-gray-700">Cedant</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.selectedTreaty.insurer?.insurer_company_name || "N/A"}
                        </div>
                    </div>
                    <div className="">
                        <span className="block text-sm font-light text-gray-700">Currency</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.selectedTreaty.currency || "N/A"}
                        </div>
                    </div>
                    <div className="">
                        <span className="block text-sm font-light text-gray-700">Brokerage (%)</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {data?.selectedTreaty?.treaty_deduction?.brokerage || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">Withholding Tax (%)</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {numeral(data?.selectedTreaty?.treaty_deduction?.withholding_tax).format("#,#.##") || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">Commission (%)</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {numeral(data?.selectedTreaty?.treaty_deduction?.commission).format("#,#.##") || "N/A"}
                        </div>
                    </div>
                    <div>
                        <span className="block text-sm font-light text-gray-700">NIC Levy</span>
                        <div className="mt-1 block w-full sm:text-sm">
                            {numeral(data?.selectedTreaty?.treaty_deduction?.nic_levy).format("#,#.##") || "N/A"}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TreatySearchFormSummary