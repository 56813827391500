import { FC, useState, useEffect } from "react";
import { DocumentIcon } from "@heroicons/react/24/outline";

interface PDFRendererProps {
  /** URL of the PDF document to render */
  url: string;
  /** Optional height of the PDF viewer in pixels or CSS units */
  height?: number | string;
}

/**
 * Component for rendering PDF documents with loading and error states
 */
const PDFRenderer: FC<PDFRendererProps> = ({ url, height = 800 }) => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const validateDocument = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(url);

        if (isMounted) {
          setIsError(!response.ok || response.status === 500);
        }
      } catch (error) {
        if (isMounted) {
          setIsError(true);
          console.error('Failed to load PDF document:', error);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    validateDocument();

    return () => {
      isMounted = false;
    };
  }, [url]);

  if (isLoading) {
    return (
      <div className="flex-1 w-full h-[800px] flex flex-col items-center justify-center space-y-4">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-primary-500"
          role="progressbar"
          aria-label="Loading document">
        </div>
        <p className="text-gray-500 font-medium">Loading document...</p>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex-1 w-full h-[800px] flex flex-col items-center justify-center space-y-4"
        role="alert"
        aria-label="Document error">
        <DocumentIcon className="h-16 w-16 text-gray-400" aria-hidden="true" />
        <p className="text-gray-500 font-medium">Document not available</p>
      </div>
    );
  }

  return (
    <div className="flex-1 w-full">
      <iframe
        title="PDF Document Viewer"
        src={`${url}#view=FitH`}
        width="100%"
        height={height}
        frameBorder="0"
        onError={() => setIsError(true)}
        onLoad={() => setIsLoading(false)}
        aria-label="PDF document content"
      />
    </div>
  );
};

export default PDFRenderer;
