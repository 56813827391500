import { Modal, TreatyClaimView } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { useTreatyClaim } from "hooks/data/use-treaty-claims";



export default function ViewTreatyClaimContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
}) {
    const searchParams = useSearch<LocationGenerics>();
    const { loading, claim } = useTreatyClaim({
        treatyClaimId: searchParams?.id || "",
    });



    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            // hideActions
            // size="6xl"
            title="View Treaty Claim"
            description="View Treaty Claim"
        >
            <TreatyClaimView claim={claim} />
        </Modal>
    );
}
