import { OfficeButton, OfficeHeader, Tabs } from "components";
import { FC } from "react";
import { useUrlState } from "utils";
import OfferOverView from "./overview";
import { UserPlus } from "lucide-react";
import { LockClosedIcon, PaperAirplaneIcon } from "@heroicons/react/24/outline";
import AddParticipantsContainer from "./add-participants";
import OfferParticipation from "./participation";
import OfferAssociates from "./associates";
import SendEmailsContainer from "./send-emails";
import CloseOfferContainer from "./close-offer";
import { useOffer } from "hooks/data/use-offers";
import FleetPicker from "./components/fleet-picker";
import { useAppLocation } from "_graphql/cache/auth";

type Props = {};



const tabs = [
  { name: "Offer Participants", href: "participants" },
  { name: "Participant's Associates", href: "associates" },
];

const OfferPage: FC<Props> = () => {
  const [modal, setModal] = useUrlState("modal");
  const [orderStatus] = useUrlState("orderStatus");
  const { params } = useAppLocation();
  const { offer } = useOffer({
    offer_id: params?.offer || ""
  });



  return (
    <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
      <OfficeHeader
        renderActions={() => (
          <>
            <FleetPicker offer={offer} />
            <OfficeButton onClick={() => setModal("create")}>
              <UserPlus className="w-5 h-5 mr-2" />
              <span className="hidden sm:inline">Add Participant</span>
            </OfficeButton>
            <OfficeButton onClick={() => setModal("send")}>
              <PaperAirplaneIcon className="w-5 h-5 mr-2" />
              <span className="hidden sm:inline">Send Emails</span>
            </OfficeButton>
            <OfficeButton onClick={() => setModal("close")}>
              <LockClosedIcon className="w-5 h-5 mr-2" />
              <span className="hidden sm:inline">Close Offer</span>
            </OfficeButton>
          </>
        )}
      />
      <Tabs
        tabs={tabs}
        orderStatus={orderStatus}
        defaultTab="Overview"
      />
      <div className="flex flex-1 overflow-y-auto">
        <div className="flex-1 mx-auto min-w-0   sm:py-6 overflow-hidden overflow-y-auto light flex">
          {!orderStatus && <OfferOverView />}
          {orderStatus === "participants" && <OfferParticipation />}
          {orderStatus === "associates" && <OfferAssociates />}
        </div>
      </div>
      <AddParticipantsContainer
        open={modal === "create"}
        setOpen={(val: boolean) => setModal(val ? "create" : undefined)}
      />
      <SendEmailsContainer
        open={modal === "send"}
        setOpen={(val: boolean) => setModal(val ? "send" : undefined)}
      />
      <CloseOfferContainer
        open={modal === "close"}
        setOpen={(val: boolean) => setModal(val ? "close" : undefined)}
      />
    </main>
  );
};

export default OfferPage;
