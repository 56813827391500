import { GetNotifications_getNotifications } from '_graphql/queries/__generated__/GetNotifications'
import { CalendarHeader, Loader, RegularSelectInput, TextInput } from 'components'
import { useNotifications } from 'hooks/data/use-notifications'
import moment from 'moment'
import { useInView } from 'react-hook-inview'
import { superstate } from '@superstate/core'
import { FC, useEffect, useMemo, useState } from 'react'
import OfferNotificationComponent from './notification-templates/offer'
import EmailNotificationComponent from './notification-templates/email'
import BusinessNotificationComponent from './notification-templates/business'
import InsurerNotificationComponent from './notification-templates/insurer'
import ReinsurerNotificationComponent from './notification-templates/reinsurer'
import ClaimNotificationComponent from './notification-templates/claim'
import DocumentSMSNotificationComponent from './notification-templates/sms'
import _ from 'lodash'
import { useAppSearch } from '_graphql/cache/auth'
import clsx from 'clsx'
import numeral from 'numeral'
import { useUrlState } from 'utils'
import { usePagination } from 'hooks'

const LIMIT = 50
const offset = superstate(0)

const NOTIFICATION_TYPES = {
    'SYSTEM:OFFER': OfferNotificationComponent,
    'SYSTEM:PLACEIT': OfferNotificationComponent,
    'SYSTEM:EMAIL': EmailNotificationComponent,
    'SYSTEM:BUSINESS': BusinessNotificationComponent,
    'SYSTEM:INSURER': InsurerNotificationComponent,
    'SYSTEM:REINSURER': ReinsurerNotificationComponent,
    'SYSTEM:CLAIM': ClaimNotificationComponent,
    'DOCUMENT:SMS': DocumentSMSNotificationComponent,
} as const

interface NotificationsPageProps { }

const NotificationsPage: FC<NotificationsPageProps> = () => {
    const searchParams = useAppSearch()
    const [ref, isVisible] = useInView()
    const [type, setType] = useUrlState('notification_type')
    const { search, setSearch } = usePagination()
    const [message, setMessage] = useState<GetNotifications_getNotifications | null>(null)

    const { notifications, fetchMore, length, loading } = useNotifications({
        pagination: {
            limit: LIMIT,
            offset: 0,
        },
        filter: {
            notificationStartDate: searchParams?.fromDate,
            notificationEndDate: searchParams?.toDate,
            notificationType: type?.replace(':', '_'),
            search
        }
    })

    const selectedMessage = useMemo(() => message || notifications[0], [message, notifications])
    const messageContent = useMemo(() =>
        JSON.parse(selectedMessage?.system_notification?.notification_content || '{}'),
        [selectedMessage]
    )

    const NotificationComponent = _.get(
        NOTIFICATION_TYPES,
        selectedMessage?.system_notification?.notification_type || 'SYSTEM:OFFER',
        OfferNotificationComponent
    )

    const handleOnEndReached = () => {
        if (length > offset.now() + LIMIT) {
            return fetchMore({
                variables: {
                    pagination: {
                        offset: offset.now() + LIMIT,
                        limit: LIMIT,
                    },
                    filter: {
                        notificationStartDate: searchParams?.fromDate,
                        notificationEndDate: searchParams?.toDate,
                        notificationType: type?.replace(':', '_'),
                        search
                    }
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                    offset.set(offset.now() + LIMIT)
                    if (!fetchMoreResult) return prev

                    return {
                        getNotifications: prev.getNotifications?.concat(fetchMoreResult.getNotifications) || [],
                        getNotificationsCount: fetchMoreResult.getNotificationsCount || 0,
                    }
                },
            })
        }
    }

    useEffect(() => {
        if (isVisible) {
            handleOnEndReached()
        }
    }, [isVisible])

    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <CalendarHeader />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 overflow-hidden overflow-y-auto light flex">
                    <div className="flex h-full w-full flex-col">
                        <div className="flex min-h-0 flex-1 overflow-hidden">
                            <main className="min-w-0 flex-1 border-t border-gray-200 xl:flex">
                                <section
                                    aria-labelledby="message-heading"
                                    className="flex h-full min-w-0 flex-1 flex-col overflow-hidden xl:order-last"
                                >
                                    <div className="min-h-0 flex-1 h-full overflow-y-auto">
                                        <div className="bg-white pb-6 pt-5 shadow">
                                            <div className="px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-8">
                                                <div className="sm:w-0 sm:flex-1">
                                                    <h1 id="message-heading" className="text-lg font-medium text-gray-900">
                                                        {messageContent.title}
                                                    </h1>
                                                    <p className="mt-1 truncate text-sm text-gray-500">
                                                        {moment(message?.created_at).format('LLL')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bg-white p-4">
                                            {selectedMessage && (
                                                <NotificationComponent
                                                    notification={message}
                                                    parsedNotification={JSON.parse(atob(messageContent?.data) || '{}')}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </section>

                                <aside className="hidden xl:order-first xl:block xl:flex-shrink-0">
                                    <div className="relative flex h-full w-96 flex-col border-r border-gray-200 bg-gray-100">
                                        <div className="flex-shrink-0">
                                            <div className="flex h-16 flex-col justify-center bg-white px-6">
                                                <div className="flex items-baseline space-x-3">
                                                    <h2 className="text-lg font-medium text-gray-900">Inbox</h2>
                                                    <p className="text-sm font-medium text-gray-500">
                                                        {numeral(length).format('#,#')} messages
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="border-b border-t flex items-center justify-between border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500">
                                                <div>Sorted by date</div>
                                                <div>
                                                    <RegularSelectInput
                                                        id="type"
                                                        hideLabel
                                                        label="Notification Type"
                                                        values={{ type }}
                                                        handleChange={(e: any) => setType(e.target.value)}
                                                        options={[
                                                            { label: 'Offer Activities', value: 'SYSTEM:OFFER' },
                                                            { label: 'Place It Activities', value: 'SYSTEM:PLACEIT' },
                                                            { label: 'Emails Sent', value: 'SYSTEM:EMAIL' },
                                                            { label: 'Business Actions', value: 'SYSTEM:BUSINESS' },
                                                            { label: 'Insurer Activities', value: 'SYSTEM:INSURER' },
                                                            { label: 'Reinsurer Activities', value: 'SYSTEM:REINSURER' },
                                                            { label: 'Claim Actions', value: 'SYSTEM:CLAIM' },
                                                            { label: 'SMS messages', value: 'DOCUMENT:SMS' },
                                                        ]}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <TextInput
                                                labelHidden
                                                id="search"
                                                placeholder="Search Notifications"
                                                values={{ search }}
                                                handleChange={(evt: any) => setSearch(evt.target.value)}
                                                handleBlur={() => { }}
                                            />
                                        </div>

                                        <nav aria-label="Message list" className="min-h-0 flex-1 overflow-y-auto">
                                            <ul role="list" className="divide-y divide-gray-200 no-scrollbar flex-1 border-b border-gray-200">
                                                {notifications.map((message) => {
                                                    const actualMessage = JSON.parse(message?.system_notification?.notification_content || '{}')
                                                    const isSelected = message?.employee_notification_id === selectedMessage?.employee_notification_id

                                                    return (
                                                        <li
                                                            key={message?.employee_notification_id}
                                                            onClick={() => setMessage(message)}
                                                            className={clsx(
                                                                'relative px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 hover:bg-gray-50 cursor-pointer transition-colors duration-150',
                                                                {
                                                                    'ring-2 ring-primary-600 bg-teal-300': isSelected
                                                                }
                                                            )}
                                                        >
                                                            <div className="flex justify-between space-x-3">
                                                                <div className="min-w-0 flex-1">
                                                                    <span className="block focus:outline-none">
                                                                        <span aria-hidden="true" className="absolute inset-0" />
                                                                        <p className="text-sm font-medium text-gray-900">{actualMessage?.title}</p>
                                                                        <p className="truncate text-sm text-gray-500">{actualMessage?.message}</p>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="mt-1">
                                                                <p className="line-clamp-2 text-sm text-gray-600">
                                                                    {moment(message?.created_at).format('LLL')}
                                                                </p>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                                <li ref={ref}>
                                                    {isVisible && loading && <Loader text="Fetching notifications..." className="py-11" />}
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </aside>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default NotificationsPage