import React from 'react'
import { Link } from 'react-location';
import { classNames } from 'utils';

type Props = {
    tabs: {
        name: string;
        href: string;
    }[];
    orderStatus: string;
    defaultTab: string;
}

const Tabs: React.FC<Props> = ({
    tabs,
    orderStatus,
    defaultTab,
}) => {
    return (
        <div className="relative">
            <div className="bg-gradient-to-r from-card-600/90 to-card-500/90 backdrop-blur-sm border-b border-shade-500/50 px-8 shadow-lg">
                <nav className="flex items-center justify-start space-x-1" aria-label="Tabs">
                    <Link
                        search={(old) => ({
                            ...old,
                            orderStatus: undefined,
                        })}
                        className={classNames(
                            "relative px-6 py-4 transition-all duration-200 ease-in-out",
                            !orderStatus ? [
                                "text-primary-300 font-semibold",
                                "after:absolute after:bottom-0 after:left-0 after:right-0",
                                "after:h-0.5 after:bg-primary-400 after:rounded-t-full"
                            ].join(" ") : [
                                "text-gray-400 hover:text-gray-200",
                                "hover:bg-white/5"
                            ].join(" "),
                            "rounded-t-lg text-sm tracking-wide"
                        )}
                        aria-current={!orderStatus ? "page" : undefined}
                    >
                        <span className="relative z-10">{defaultTab}</span>
                    </Link>

                    {tabs.map((_orderStatus) => (
                        <Link
                            key={_orderStatus.name}
                            search={(old) => ({
                                ...old,
                                orderStatus: _orderStatus.href,
                            })}
                            className={classNames(
                                "relative px-6 py-4 transition-all duration-200 ease-in-out",
                                orderStatus === _orderStatus.href ? [
                                    "text-primary-300 font-semibold",
                                    "after:absolute after:bottom-0 after:left-0 after:right-0",
                                    "after:h-0.5 after:bg-primary-400 after:rounded-t-full"
                                ].join(" ") : [
                                    "text-gray-400 hover:text-gray-200",
                                    "hover:bg-white/5"
                                ].join(" "),
                                "rounded-t-lg text-sm tracking-wide"
                            )}
                            aria-current={orderStatus === _orderStatus.href ? "page" : undefined}
                        >
                            <span className="relative z-10">{_orderStatus.name}</span>
                        </Link>
                    ))}
                </nav>
            </div>
        </div>
    )
}

export default Tabs