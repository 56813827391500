import { useMutation } from "@apollo/client";
import { useAppLocation, useAppSearch } from "_graphql/cache/auth";
import {
  MakeNonProportionalPayment,
  MakeNonProportionalPaymentVariables,
} from "_graphql/mutation/__generated__/MakeNonProportionalPayment";
import { makeNonProportionalPayment } from "_graphql/mutation/payment";
import { Modal } from "components";
import { NonProportionalPaymentForm } from "components/forms";
import { useFormik } from "formik";
import { useNonProportionalPayments, useTreaty } from "hooks/data/use-treaties";
import numeral from "numeral";
import { useEffect, useMemo } from "react";
import toast from "react-hot-toast";
import { getSumOFNPPayments, wrapClick } from "utils";

import * as yup from "yup";

const formSchema = yup.object().shape({
  payment_type: yup.string().required("Payment type is required"),
  auto_payment_receipt: yup.boolean(),
  layer_limit: yup.string().required("Layer limit is required"),
  installment_type: yup.string().required("Installment type is required"),
  payment_from: yup.object().shape({
    bank_name: yup.string().required("Bank name is required"),
    cheque_number: yup.string().when("$payment_type", {
      is: (val: string) => val === "Cheque",
      then(schema) {
        return schema.required("Cheque number is required");
      },
      otherwise(schema) {
        return schema.notRequired();
      },
    }),
    date_on_cheque: yup.string().when("$payment_type", {
      is: (val: string) => val === "Cheque",
      then(schema) {
        return schema.required("Date on cheque is required");
      },
      otherwise(schema) {
        return schema.notRequired();
      },
    }),
  }),
  offer_payment_comment: yup.string(),
  payment_to: yup.string().required("Payment to is required"),
  payment_details: yup.object().shape({
    payment_amount: yup
      .number()
      .required("Payment amount is required")
      .min(0, "Payment amount should not be less than 0")
      .test(
        "max-payment",
        "Payment amount should not be more than amount to pay",
        function (value) {
          const {
            amountToPay,
            payment_details: {
              conversion: { rate },
            },
          } = this.options.context as any;
          // console.log("Data", { amountToPay, rate })
          return value <= amountToPay * rate;
        }
      ),
    currency: yup.string().required("Currency is required"),
    conversion: yup.object().shape({
      addExchangeRate: yup.boolean(),
      rate: yup.number().required("Rate is required"),
      currency: yup.string().when("addExchangeRate", {
        is: true,
        then(schema) {
          return schema.required("Currency is required");
        },
        otherwise(schema) {
          return schema.notRequired();
        },
      }),
    }),
  }),
  amountToPay: yup.number().required("Amount to pay is required"),
});

type Props = {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
};

const CreateNonProportionalPaymentCOntainer = ({ open, setOpen }: Props) => {
  const { params } = useAppLocation();
  const searchParams = useAppSearch();

  const { loading: fetchingTreaty, treaty } = useTreaty({
    treatyId: params?.treaty,
  });
  const { payments, loading: fetchingPayments } = useNonProportionalPayments({
    filter: {
      treaty_id: params?.treaty,
    },
  });
  const [create, { loading }] = useMutation<
    MakeNonProportionalPayment,
    MakeNonProportionalPaymentVariables
  >(makeNonProportionalPayment, {
    refetchQueries: ["GetNonProportionalTreatyPayments"],
  });
  const form = useFormik({
    initialValues: {
      payment_type: "",
      auto_payment_receipt: false,
      amountToPay: 2300,
      layer_limit: "",
      installment_type: "",
      payment_from: {
        bank_name: "",
        cheque_number: "",
        date_on_cheque: "",
      },
      offer_payment_comment: "",
      payment_to: "",
      payment_details: {
        payment_amount: 0,
        currency: "",
        conversion: {
          rate: 1,
          currency: "",
          addExchangeRate: false,
        },
      },
    },
    validationSchema: formSchema,
    onSubmit: (value, helpers) => {
      console.log("Value", value);
      create({
        variables: {
          payment: {
            payment_amount: value.payment_details.payment_amount,
            payment_status:
              value.amountToPay > value.payment_details.payment_amount
                ? "UNPAID"
                : " PAID",
            auto_payment_receipt: value.auto_payment_receipt,
            installment_type: parseInt(value.installment_type),
            payment_comment: value.offer_payment_comment,
            treaty_account_id: value.layer_limit,
            treaty_id: params?.treaty,
            uuid: value.layer_limit,
            payment_details: JSON.stringify({
              currency: value.payment_details.currency,
              payment_type: value.payment_type,
              payment_from: {
                cheque_number: value.payment_from.cheque_number
                  ? value.payment_from.cheque_number
                  : "N/A",
                bank_name: value.payment_from.bank_name,
                date_on_cheque: value.payment_from.date_on_cheque
                  ? value.payment_from.date_on_cheque
                  : "N/A",
              },
              payment_to: value.payment_to,
              conversion: {
                rate: value.payment_details.conversion.rate,
                currency:
                  value?.payment_details?.currency ||
                  value.payment_details.conversion.currency ||
                  "N/A",
                addExchangeRate:
                  value.payment_details.conversion.addExchangeRate,
              },
            }),
          },
        },
      })
        .then((res) => {
          if (res.data?.makeNonProportionalPayment) {
            toast.success("Payment recorded successfully");
            helpers.resetForm();
            setOpen?.(false);
          } else {
            toast.error("Payment could not be recorded");
          }
        })
        .catch((error) => {
          console.log("ERROR", error);
          toast.error("Payment could not be recorded");
        });
    },
  });

  const percentage = useMemo(() => {
    if (treaty?.treaty_participants?.length && form.values.layer_limit) {
      const layerNumber =
        JSON.parse(treaty?.layer_limit || "[]")?.findIndex(
          (el: any) => el.uuid === form.values.layer_limit
        ) + 1;
      const count =
        treaty?.treaty_participants
          .filter((el) => {
            return el?.layer_number === layerNumber;
          })
          ?.reduce(
            (acc, cur) => acc + (cur?.treaty_participation_percentage || 0),
            0
          ) || 0;

      return count;
    }
    return 0;
  }, [treaty?.treaty_participants, form.values.layer_limit]);

  useEffect(() => {
    form.setFieldValue("payment_details.payment_amount", 0);
    if (form.values.layer_limit && form.values.installment_type) {
      const oldPayments = getSumOFNPPayments({
        treaty_np_payments: payments,
        uuid: form.values.layer_limit,
        installment_type: form.values.installment_type,
        treaty_np_payment_id: searchParams.payment_id,
      });
      // console.log("Old payments", oldPayments);
      const layer = JSON.parse(treaty?.layer_limit || "[]")?.find(
        (el: any) => el.uuid === form.values.layer_limit
      );
      // console.log(
      //   "Layer",
      //   percentage,
      //   numeral(layer?.m_and_d_premium).value(),
      //   layer?.installment_type
      // );
      const amountToPay =
        numeral(percentage)
          .divide(100)
          .multiply(numeral(layer?.m_and_d_premium).value())
          .multiply(numeral(treaty?.order_hereon).divide(100).value())
          .divide(layer?.installment_type)
          .subtract(oldPayments)
          .value() || 0;
      // console.log("Amount to pay", amountToPay);
      form.setFieldValue(
        "amountToPay",
        amountToPay <= 0
          ? 0
          : numeral(amountToPay)
            .multiply(form.values.payment_details.conversion.rate)
            .value()
      );
    }
  }, [
    form.values.layer_limit,
    form.values.installment_type,
    form.values.payment_details.conversion.addExchangeRate,
    form.values.payment_details.conversion.rate,
    percentage,
  ]);

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      loading={fetchingTreaty || fetchingPayments}
      title="Make payment"
      description="Make a payment to the treaty."
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={wrapClick(form.handleSubmit)}
          >
            {loading ? "Please wait ..." : "Make Payment"}
          </button>
        </>
      )}
    >
      <NonProportionalPaymentForm
        form={form}
        treaty={treaty}
        amountToPay={form.values.amountToPay}
      />
    </Modal>
  );
};

export default CreateNonProportionalPaymentCOntainer;
