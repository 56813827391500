import React from 'react'
import { Link } from 'react-location'

type Props = {
    notification: any
    parsedNotification: any
}

const InsurerNotificationComponent = ({ notification, parsedNotification }: Props) => {
    if (parsedNotification.hasOwnProperty("assoc_email")) {
        return (
            <div className="max-w-5xl mx-auto px-8 py-6">
                <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-6 rounded-r">
                    <p className="text-amber-800">
                        {JSON.parse(notification?.system_notification?.notification_content || "{}")?.message}
                    </p>
                </div>

                <div className="bg-white shadow rounded-lg p-6 mb-6">
                    <h2 className="text-lg font-medium mb-6">Associate Details</h2>
                    <div className="grid grid-cols-2 gap-6">
                        <div>
                            <p className="text-sm text-gray-500">Associate Name</p>
                            <p className="font-medium">
                                {parsedNotification?.assoc_first_name} {parsedNotification?.assoc_last_name}
                            </p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Position</p>
                            <p className="font-medium">{parsedNotification?.position}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Email</p>
                            <p className="font-medium">{parsedNotification?.assoc_email}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Phone Numbers</p>
                            <p className="font-medium">
                                {parsedNotification?.assoc_primary_phonenumber}
                                {parsedNotification?.assoc_secondary_phonenumber && (
                                    <span className="text-gray-500">
                                        , {parsedNotification?.assoc_secondary_phonenumber}
                                    </span>
                                )}
                            </p>
                        </div>
                    </div>
                </div>

                <Link
                    to={`/insurers/${parsedNotification?.insurer_id}/view`}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                >
                    View Insurer Details
                </Link>
            </div>
        )
    } else if (parsedNotification.hasOwnProperty("insurer_company_email")) {
        return (
            <div className="max-w-5xl mx-auto px-8 py-6">
                <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-6 rounded-r">
                    <p className="text-amber-800">
                        {JSON.parse(notification?.system_notification?.notification_content || "{}")?.message}
                    </p>
                </div>

                <div className="bg-white shadow rounded-lg p-6 mb-6">
                    <div className="flex items-center justify-center mb-6">
                        <div className="h-16 w-16 rounded-full bg-blue-100 flex items-center justify-center">
                            <span className="text-blue-600 text-xl font-semibold">
                                {parsedNotification?.insurer_abbrv}
                            </span>
                        </div>
                    </div>

                    <div className="grid grid-cols-2 gap-6">
                        <div>
                            <p className="text-sm text-gray-500">Company Name</p>
                            <p className="font-medium">{parsedNotification?.insurer_company_name}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Website</p>
                            <p className="font-medium">{parsedNotification?.insurer_company_website}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Email</p>
                            <p className="font-medium">{parsedNotification?.insurer_company_email}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Address</p>
                            <p className="font-medium">
                                {parsedNotification?.address?.suburb} - {parsedNotification?.address?.region},{" "}
                                {parsedNotification?.address?.country}
                            </p>
                        </div>
                    </div>
                </div>

                <Link
                    to={`/insurers/${parsedNotification?.insurer_id}/view`}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                >
                    View Insurer Details
                </Link>
            </div>
        )
    } else {
        return (
            <div className="max-w-5xl mx-auto px-8 py-6">
                <div className="bg-amber-50 border-l-4 border-amber-400 p-4 rounded-r">
                    <p className="text-amber-800">
                        {JSON.parse(notification?.system_notification?.notification_content || "{}")?.message}
                    </p>
                </div>
            </div>
        )
    }
}

export default InsurerNotificationComponent