import { TextInput } from "components/core";
import { FormikProps, useFormik } from "formik";
import { FC } from "react";
import { classNames, wrapClick } from "utils";
import { gql, useLazyQuery } from "@apollo/client";
import { ClipboardDocumentListIcon, DocumentPlusIcon, TrashIcon } from "@heroicons/react/24/outline";
import numeral from "numeral";
import * as Yup from "yup";

export const SEARCH_TREATIES = gql`
query SearchTreaties($args: TreatyFilters) {
  rows: getTreaties(args: $args) {
    currency
    treaty_id
    treaty_reference
    kek_reference
    treaty_details
    order_hereon
    treaty_payment_status
    layer_limit
    insurer {
      insurer_id
      insurer_company_name
      insurer_company_email
      insurer_company_website
      insurer_address {
        suburb
        region
        country
      }
    }
    treaty_deduction {
      treaty_associate_deduction_id
      commission
      brokerage
      nic_levy
      withholding_tax
      treaty_period_from
      treaty_period_to
      treaty_program {
        treaty_program_id
        treaty_name
        treaty_details
        treaty_type
      }
    }
  }
}
`;

interface TreatyFormProps {
    handleNext: (values: any) => void;
    handlePrevious: () => void;
    initialValues: any;
    values: any;
    handleCancel: () => void;
    parentForm: FormikProps<any>;
}

const TreatyForm: FC<TreatyFormProps> = ({
    initialValues,
    handleNext,
    handleCancel,
    parentForm,
}) => {
    const form = useFormik<{ selectedTreaty: Record<string, any> | null }>({
        initialValues: {
            selectedTreaty: null,
        },
        validationSchema: Yup.object().shape({
            selectedTreaty: Yup.object().nullable().required("Please select a treaty"),
        }),
        onSubmit: (values) => {
            handleNext(values);
        },
        onReset: () => {
            handleCancel?.();
        },
    });

    const [searchTreaty, { loading, data }] = useLazyQuery(SEARCH_TREATIES);

    const servicePointSearchForm = useFormik({
        initialValues: {
            search: "",
        },
        onSubmit: (values) => {
            searchTreaty({
                variables: {
                    args: {
                        insurer_id: "",
                        broker_id: "",
                        treaty_payment_status: [],
                        treaty_claim_status: [],
                        treaty_expiry_status: [],
                        treaty_program_type: [],
                        offset: 0,
                        limit: 30,
                        search: values.search,
                    }
                },
            })
        },
    });

    const handleSetOfferDetail = (value: any) => {
        // Set the selected treaty as the only selected offer
        form?.setFieldValue("selectedTreaty", value);
        parentForm?.setFieldValue("claims.treaties.0.policy_number", value?.treaty_reference);
    };



    return (
        <div className="flex-1 flex flex-col overflow-hidden">
            <div className="space-y-6 divide-y divide-gray-200 p-6 flex-1 overflow-y-auto">
                <div className="">
                    <span className="text-xs font-light">Search for Treaty</span>
                    <form
                        onSubmit={servicePointSearchForm.handleSubmit}
                        className="grid grid-cols-8 gap-6 mt-2"
                    >
                        <div className="col-span-6 sm:col-span-3">
                            <TextInput
                                id="search"
                                label="Treaty Reference"
                                type="text"
                                placeholder="e.g. POL-0000001"
                                {...servicePointSearchForm}
                            />
                        </div>
                        <div className="col-span-6 sm:col-span-2 items-end justify-end flex">
                            <button
                                type="submit"
                                className="w-full inline-flex justify-center rounded-md border border-transparent bg-primary-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                            >
                                {loading ? "Searching Treaty..." : "Search Treaty"}
                            </button>
                        </div>
                    </form>
                    <div className="grid grid-cols-3 gap-6 mt-6 max-h-64 overflow-y-scroll h-full pt-6 border-t border-gray-200">
                        {data?.rows?.map?.((offer: any) => (
                            <TreatyItem
                                key={offer.treaty_id}
                                isActive={parentForm.values?.selectedTreaty?.treaty_id === offer.treaty_id}
                                onClick={wrapClick(() => handleSetOfferDetail(offer))}
                                owner={offer}
                            />
                        ))}
                    </div>
                </div>

                <div className="pt-6">
                    <span className="text-xs font-light">Selected Treaty</span>
                    {form?.values?.selectedTreaty ? (
                        <div className="border mt-2 border-gray-300 border-dashed bg-gray-100 divide-y divide-dashed divide-gray-300 rounded-md">
                            <div className="grid grid-cols-4 gap-x-4 gap-y-6  p-4">
                                <div className="col-span-2">
                                    <span className="block text-sm font-light text-gray-700">Treaty Ref#</span>
                                    <div className="mt-1 inline-block w-full sm:text-sm flex-wrap">
                                        {form.values?.selectedTreaty.treaty_reference || "N/A"}
                                    </div>
                                </div>
                                <div className="col-span-2">
                                    <span className="block text-sm font-light text-gray-700">Treaty Program</span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {form?.values?.selectedTreaty?.treaty_deduction?.treaty_program?.treaty_name || "N/A"}
                                    </div>
                                </div>
                                <div className="col-span-4">
                                    <span className="block text-sm font-light text-gray-700">Cedant</span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {form.values?.selectedTreaty.insurer?.insurer_company_name || "N/A"}
                                    </div>
                                </div>

                            </div>
                            <div className="grid grid-cols-3 gap-x-4 gap-y-6  p-4">
                                <div className="">
                                    <span className="block text-sm font-light text-gray-700">Currency</span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {form.values?.selectedTreaty.currency || "N/A"}
                                    </div>
                                </div>
                                <div className="">
                                    <span className="block text-sm font-light text-gray-700">Brokerage (%)</span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {form?.values?.selectedTreaty?.treaty_deduction?.brokerage || "N/A"}
                                    </div>
                                </div>
                                <div>
                                    <span className="block text-sm font-light text-gray-700">Withholding Tax (%)</span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {numeral(form?.values?.selectedTreaty?.treaty_deduction?.withholding_tax).format("#,#.##") || "N/A"}
                                    </div>
                                </div>
                                <div>
                                    <span className="block text-sm font-light text-gray-700">Commission (%)</span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {numeral(form?.values?.selectedTreaty?.treaty_deduction?.commission).format("#,#.##") || "N/A"}
                                    </div>
                                </div>
                                <div>
                                    <span className="block text-sm font-light text-gray-700">NIC Levy</span>
                                    <div className="mt-1 block w-full sm:text-sm">
                                        {numeral(form?.values?.selectedTreaty?.treaty_deduction?.nic_levy).format("#,#.##") || "N/A"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="flex mt-2 w-full items-center justify-center">
                            <div className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-6 py-24 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                                <DocumentPlusIcon
                                    className="mx-auto h-10 w-10 text-gray-400"
                                    stroke="currentColor"
                                    strokeWidth={1}
                                    aria-hidden="true"
                                />
                                <h3 className="mt-2 text-sm font-medium text-gray-900">No Treaty Selected</h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    Get started by searching for a treaty above.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="bg-gray-50 dark:bg-gray-800 px-4 py-3 sm:py-4 sm:px-6 sm:flex sm:flex-row-reverse border-t border-gray-200">
                <button
                    type="button"
                    onClick={wrapClick(form.handleSubmit)}
                    disabled={!form.isValid}
                    className={classNames(
                        form.isValid ? "cursor-pointer" : "cursor-not-allowed",
                        "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:ml-3 sm:w-auto sm:text-sm"
                    )}
                >
                    Next
                </button>
                <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 dark:border-gray-600 shadow-sm px-4 py-2 bg-white dark:bg-gray-900 text-base font-medium text-gray-700 dark:text-gray-200 hover:bg-gray-50 hover:dark:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={wrapClick(form.resetForm)}
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default TreatyForm;

function TreatyItem({
    owner,
    onClick,
    isActive,
}: {
    owner: any;
    onClick: any;
    isActive: boolean;
}) {
    return (
        <button
            onClick={onClick}
            className={classNames(
                isActive
                    ? "border-indigo-800 border-2 dark:border-primary-600 bg-white dark:bg-primary-800 hover:border-primary-700 dark:hover:border-gray-200"
                    : "border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 hover:border-gray-400 dark:hover:border-gray-500",
                "focus:outline-none relative rounded-lg border px-3 py-3 shadow-sm flex items-center space-x-3 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
            )}
        >
            <div className="flex-shrink-0">
                <ClipboardDocumentListIcon />
            </div>
            <div className="flex-1 min-w-0 text-left">
                <p className="text-sm font-medium text-gray-900 dark:text-white truncate">
                    {owner?.treaty_reference || "N/A"}
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400 truncate">
                    {owner?.currency || "N/A"}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400 truncate">
                    {owner?.insurer?.insurer_company_name || "N/A"}
                </p>
            </div>
        </button>
    );
}
