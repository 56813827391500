import { ResponsivePie } from '@nivo/pie'
import numeral from 'numeral'
import { FC } from 'react'

interface PieProps {
    data: {
        id: string
        label: string
        value: number
        color: string
        bgColor: string
    }[]
    innerRadius?: number
}

const Pie: FC<PieProps> = ({ data, innerRadius = 0.6 }) => (
    <ResponsivePie
        data={data}
        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
        innerRadius={innerRadius}
        padAngle={0.5}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]]
        }}
        colors={{ datum: 'data.color' }}
        enableArcLinkLabels={false}
        arcLabelsSkipAngle={10}
        enableArcLabels={true}
        arcLabelsTextColor="#ffffff"
        arcLabel={e => numeral(e.value).format('0.0a')}
        motionConfig="gentle"
        transitionMode="pushIn"
        animate={true}
    />
)

export default Pie