import { useAppNavigate, useAppSearch } from '_graphql/cache/auth'
import { BrokerOffers_brokerOffers_data } from '_graphql/queries/__generated__/BrokerOffers'
import clsx from 'clsx'
import { ActionButton, OfficeHeader, Paginator, Show, TableComponent } from 'components'
import { Action } from 'components/buttons/action-button'
import { usePagination } from 'hooks'
import { usePlaceItOffers } from 'hooks/data/use-placeit-offers'
import numeral from 'numeral'
import { FC } from 'react'
import { extractVehicleNumberFromDetails, useUrlState } from 'utils'
import ApproveContainer from './approve'
import DeclineContainer from './reject'
import SendMessageContainer from './message-thread'
import ViewOfferSlipContainer from './view-slip'
import { TransactionStatus } from '_graphql/__generated__/globalTypes-place-it'

type Props = {}

const PlaceIfOffersPage: FC<Props> = () => {
    const [modal, setModal] = useUrlState("modal");
    const navigate = useAppNavigate();
    const searchParams = useAppSearch()
    const { currentPage, handlePageChange, limit, setPage } = usePagination()
    const { offers, loading, refetch } = usePlaceItOffers({
        brokerId: "1",
        filters: {
            page: currentPage,
            limit: limit
        }
    });

    const dispatchAction =
        (id: string, action: Exclude<Action, "expand" | "goto" | "clone">) =>
            () => {
                navigate({
                    search: (old) => ({
                        ...old,
                        id,
                        modal: action,
                    }),
                });
            };

    return (
        <main className="flex-1 flex flex-col overflow-hidden bg-shade-500">
            <OfficeHeader />
            <div className="flex flex-1 overflow-y-auto">
                <div className="flex-1 mx-auto min-w-0  py-4 sm:py-6 overflow-hidden overflow-y-auto light flex">
                    <TableComponent
                        title={"Broker Offers"}
                        data={offers}
                        emptyMessage='No offers found'
                        columns={[
                            {
                                name: "Policy #",
                                accessor: "offer_detail.policy_number",
                                render: (value: BrokerOffers_brokerOffers_data) => (
                                    <div>
                                        <span className="font-bold text-xs">
                                            {value.offer_detail?.policy_number || "N/A"}
                                        </span>
                                        {/* <div>
                                            {value?.expiry_status}
                                        </div> */}
                                    </div>
                                ),
                            },
                            {
                                name: "Insurance Company",
                                accessor: "insurer.insurer_company_name",
                                render: (value: BrokerOffers_brokerOffers_data) => (
                                    <div className="flex flex-col text-xs">
                                        <span className="font-medium">
                                            {value.insurer?.insurer_company_name || "N/A"}
                                        </span>
                                        <span className="font-light">
                                            {value.insurer?.insurer_company_email || "N/A"}
                                        </span>
                                    </div>
                                ),
                            },
                            {
                                name: "Insured",
                                accessor: "offer_detail.insured_by",
                                render: (value: BrokerOffers_brokerOffers_data) => {
                                    const details = JSON.parse(
                                        value?.offer_detail?.offer_details || "[]"
                                    );
                                    return (
                                        <div className="flex flex-col text-xs ">
                                            <span className="font-medium">
                                                {value.offer_detail?.insured_by || "N/A"}
                                            </span>
                                            <span className="font-light">
                                                {[
                                                    "Motor Comprehensive Fleet",
                                                    "Motor Comprehensive",
                                                    "Motor Comprehensive (Automobile Fac Facility)",
                                                ].includes(value?.class_of_business?.business_name || "")
                                                    ? extractVehicleNumberFromDetails(details)
                                                    : ""}
                                            </span>
                                        </div>
                                    );
                                },
                            },
                            {
                                name: "Class Of Business",
                                accessor: "classofbusiness.business_name",
                                render: (value: BrokerOffers_brokerOffers_data) => (
                                    <div className="flex flex-col text-xs">
                                        <span className="font-medium">
                                            {value.class_of_business?.business_name || "N/A"}
                                        </span>
                                    </div>
                                ),
                            },
                            {
                                name: "Sum Insured",
                                accessor: "sum_insured",
                                render: (value: BrokerOffers_brokerOffers_data) => (
                                    <div className="flex flex-col text-xs">
                                        <span className="font-medium">
                                            {value?.offer_detail?.currency}{" "}
                                            {numeral(value.sum_insured).format("0,0 ") || "N/A"}
                                        </span>
                                    </div>
                                ),
                            },
                            {
                                name: "Premium",
                                accessor: "premium",
                                render: (value: BrokerOffers_brokerOffers_data) => (
                                    <div className="flex flex-col text-xs">
                                        <span className="font-medium">
                                            {value?.offer_detail?.currency}{" "}
                                            {numeral(value.premium).format("0,0 ") || "N/A"}
                                        </span>
                                    </div>
                                ),
                            },
                            {
                                name: "Offer Date",
                                accessor: "created_at",
                                render: (value: BrokerOffers_brokerOffers_data) =>
                                    new Date(value.created_at).toLocaleDateString(),
                            },
                            {
                                name: "Payment Status",
                                accessor: "payment_status",
                                render: (value: BrokerOffers_brokerOffers_data) => (
                                    <div className="flex">
                                        <div className={clsx("p-1 rounded-lg", {
                                            "bg-green-100 text-green-600": value.payment_status === "PAID",
                                            "bg-red-50 text-red-600": value.payment_status === "UNPAID",
                                            "bg-sky-50 text-sky-600": value.payment_status === "PARTPAYMENT",
                                        }, "text-xs font-medium")}>
                                            <span>{value.payment_status}</span>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                name: "Transaction Status",
                                accessor: "transaction_status",
                                render: (value: BrokerOffers_brokerOffers_data) => (
                                    <div className="flex">
                                        <div className={clsx("p-1 rounded-lg", {
                                            "bg-green-100 text-green-600": value.transaction_status === TransactionStatus.APPROVED,
                                            "bg-red-50 text-red-600": value.transaction_status === TransactionStatus.REJECTED,
                                            "bg-sky-50 text-sky-600": value.transaction_status === TransactionStatus.INITIATED,
                                            "bg-orange-50 text-orange-600": value.transaction_status === TransactionStatus.MODIFY,
                                        }, "text-xs font-medium")}>
                                            <span>{value.transaction_status}</span>
                                        </div>
                                    </div>
                                )
                            },
                            {
                                name: "Underwriter",
                                accessor: "employee.employee_first_name",
                                render: (value: BrokerOffers_brokerOffers_data) => (
                                    <div className="flex flex-row leading-0">
                                        <span>
                                            {value?.insurer_associate?.assoc_first_name}{" "}
                                            {value?.insurer_associate?.assoc_last_name}
                                        </span>
                                    </div>
                                ),
                            },
                            {
                                name: "Actions",
                                accessor: "actions",
                                render: (value: BrokerOffers_brokerOffers_data) => (
                                    <>
                                        <ActionButton
                                            action="send"
                                            tooltip="View Message Thread"
                                            onClick={dispatchAction(value?.id, "send")}
                                        />
                                        <ActionButton
                                            action="clone"
                                            tooltip="View Placing slip"
                                            onClick={dispatchAction(value?.id, "view")}
                                        />
                                        <Show if={value?.transaction_status === TransactionStatus.APPROVED}>
                                            <>
                                                <ActionButton
                                                    action="approve"
                                                    tooltip="Accept Offer"
                                                    onClick={dispatchAction(value?.id, "approve")}
                                                />
                                                <ActionButton
                                                    action="close"
                                                    tooltip="Reject Offer"
                                                    onClick={dispatchAction(value?.id, "reject")}
                                                />
                                            </>
                                        </Show>
                                    </>
                                ),
                            },
                        ]}
                        refetch={refetch}
                        loading={loading}
                        hasSearch
                        pagination={() => <Paginator offset={0} limit={0} totalItems={0} currentSize={0} setOffset={setPage} />}
                    />
                </div>
            </div>
            {searchParams?.modal === "approve" && <ApproveContainer open={true} setOpen={(value) => navigate({
                search(prev) {
                    return {
                        ...prev,
                        modal: value ? "approve" : undefined,
                        id: value ? searchParams?.id : undefined,
                    }
                }
            })} />}
            {searchParams?.modal === "reject" && <DeclineContainer open={true} setOpen={(value) => navigate({
                search(prev) {
                    return {
                        ...prev,
                        modal: value ? "reject" : undefined,
                        id: value ? searchParams?.id : undefined,
                    }
                }
            })} />}
            {searchParams?.modal === "send" && <SendMessageContainer open={true} setOpen={(value) => navigate({
                search(prev) {
                    return {
                        ...prev,
                        modal: value ? "send" : undefined,
                        id: value ? searchParams?.id : undefined,
                    }
                }
            })} />}
            {searchParams?.modal === "view" && <ViewOfferSlipContainer open={true} setOpen={(value) => navigate({
                search(prev) {
                    return {
                        ...prev,
                        modal: value ? "view" : undefined,
                        id: value ? searchParams?.id : undefined,
                    }
                }
            })} />}
        </main>
    )
}

export default PlaceIfOffersPage