import _ from 'lodash'
import numeral from 'numeral'
import { useMemo } from 'react'
import { Link } from 'react-location'

type Props = {
    notification: any
    parsedNotification: any
}

const OfferNotificationComponent = ({ notification, parsedNotification }: Props) => {
    const paymentDetails = useMemo(() => JSON.parse(parsedNotification?.payment_details?.payment_details || "{}"), [parsedNotification])

    return (
        <div className="px-8 py-6 max-w-5xl mx-auto">
            <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-6 rounded-r">
                <p className="text-amber-800">
                    {JSON.parse(notification?.system_notification?.notification_content || "{}")?.message}
                </p>
            </div>

            <div className="bg-white shadow rounded-lg p-6 mb-6">
                <div className="flex items-center justify-between mb-4">
                    <h2 className="text-lg font-medium">
                        Offer: <span className="text-gray-900">{parsedNotification?.policy_number}</span>
                    </h2>
                    <span
                        className={`px-3 py-1 text-sm font-medium rounded-full ${
                            parsedNotification?.offer_status === "OPEN"
                                ? "bg-blue-100 text-blue-800"
                                : parsedNotification?.offer_status === "PENDING"
                                    ? "bg-amber-100 text-amber-800"
                                    : "bg-emerald-100 text-emerald-800"
                        }`}
                    >
                        {parsedNotification?.offer_status}
                    </span>
                </div>

                <div className="grid grid-cols-3 gap-6 mb-6">
                    <div>
                        <p className="text-sm text-gray-500">Created By</p>
                        <p className="font-medium">{parsedNotification?.employee_name}</p>
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">Created On</p>
                        <p className="font-medium">{new Date(parsedNotification?.created_at).toDateString()}</p>
                    </div>
                    <div>
                        <p className="text-sm text-gray-500">Class of Business</p>
                        <p className="font-medium">{parsedNotification?.class_of_business}</p>
                    </div>
                </div>

                <div className="border-t border-gray-200 pt-6">
                    <h3 className="text-lg font-medium mb-4">Offer Details</h3>
                    <div className="grid grid-cols-2 gap-6">
                        <div>
                            <p className="text-sm text-gray-500">Policy Number</p>
                            <p className="font-medium">{parsedNotification?.policy_number}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Insured</p>
                            <p className="font-medium">{parsedNotification?.insured_by}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Period of Insurance</p>
                            <p className="font-medium">{parsedNotification?.period_of_insurance}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Reinsured</p>
                            <p className="font-medium">{parsedNotification?.reinsured}</p>
                        </div>
                    </div>
                </div>

                <div className="border-t border-gray-200 pt-6 mt-6">
                    <h3 className="text-lg font-medium mb-4">Financial Details</h3>
                    <div className="grid grid-cols-2 gap-6">
                        <div>
                            <p className="text-sm text-gray-500">Facultative Premium</p>
                            <p className="font-medium">
                                {parsedNotification?.currency} {numeral(parsedNotification?.fac_premium).format("#,#.##")}
                            </p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Brokerage</p>
                            <p className="font-medium">{parsedNotification?.brokerage}%</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Facultative Sum Insured</p>
                            <p className="font-medium">
                                {parsedNotification?.currency} {numeral(parsedNotification?.fac_sum_insured).format("#,#.##")}
                            </p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-500">Commission</p>
                            <p className="font-medium">{parsedNotification?.commission}%</p>
                        </div>
                    </div>
                </div>

                {!_.isEmpty(paymentDetails) && (
                    <div className="border-t border-gray-200 pt-6 mt-6">
                        <h3 className="text-lg font-medium mb-4">Payment Information</h3>
                        <div className="grid grid-cols-2 gap-6">
                            <div>
                                <p className="text-sm text-gray-500">Amount Paid</p>
                                <p className="font-medium">
                                    {parsedNotification?.currency} {numeral(parsedNotification?.payment_details?.amount_paid).format("#,#.##")}
                                </p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-500">Payment Date</p>
                                <p className="font-medium">{new Date(parsedNotification?.payment_details?.payment_date).toDateString()}</p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-500">Payment Type</p>
                                <p className="font-medium">{paymentDetails?.payment_type}</p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-500">Payment Comment</p>
                                <p className="font-medium">{parsedNotification?.payment_details?.offer_payment_comment}</p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-500">Payment From</p>
                                <p className="font-medium">{paymentDetails?.payment_from?.bank_name}</p>
                            </div>
                            <div>
                                <p className="text-sm text-gray-500">Payment To</p>
                                <p className="font-medium">{paymentDetails?.payment_to}</p>
                            </div>
                            {paymentDetails?.payment_type === "Cheque" && (
                                <div>
                                    <p className="text-sm text-gray-500">Cheque Number</p>
                                    <p className="font-medium">{paymentDetails?.payment_from?.cheque_number || "Not Specified"}</p>
                                </div>
                            )}
                        </div>
                    </div>
                )}

                {parsedNotification?.offer_deductions && (
                    <div className="border-t border-gray-200 pt-6 mt-6">
                        <h3 className="text-lg font-medium mb-4">Deductions</h3>
                        <div className="overflow-x-auto">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead>
                                    <tr>
                                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reinsurer</th>
                                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Withholding Tax</th>
                                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nic Levy</th>
                                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Agreed Brokerage</th>
                                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Agreed Commission</th>
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200">
                                    {parsedNotification?.offer_deductions.map((deduction: any, key: number) => (
                                        <tr key={key}>
                                            <td className="px-4 py-3 text-sm">{deduction.reinsurer_name}</td>
                                            <td className="px-4 py-3 text-sm">{deduction.deductions.withholding_tax}</td>
                                            <td className="px-4 py-3 text-sm">{deduction.deductions.nic_levy}</td>
                                            <td className="px-4 py-3 text-sm">{deduction.deductions.brokerage}</td>
                                            <td className="px-4 py-3 text-sm">{deduction.deductions.commission}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                <div className="flex space-x-4 mt-8">
                    {["PENDING", "OPEN"].includes(parsedNotification?.offer_status) && (
                        <Link
                            to={`/create-slip/${parsedNotification?.offer_id}/view`}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                        >
                            View Offer
                        </Link>
                    )}
                    {parsedNotification?.offer_status === "CLOSED" && (
                        <Link
                            to="/create-closing"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                        >
                            View Closing List
                        </Link>
                    )}
                    {paymentDetails && (
                        <Link
                            to={`/insurers/${parsedNotification?.insurer_id}/view`}
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                        >
                            View Insurer
                        </Link>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OfferNotificationComponent