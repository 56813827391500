import { MessageView, Modal, OfficeButton } from "components";
import { useAppSearch, useCurrentUser } from "_graphql/cache/auth";
import { usePlaceItOfferMessages } from "hooks/data/use-placeit-offers";
import { useFormik } from "formik";
import { wrapClick } from "utils";
import { useMutation } from "@apollo/client";
import { SendTheadMessage, SendTheadMessageVariables } from "_graphql/mutation/__generated__/SendTheadMessage";
import { archiveMessageThread, sendPlaceItOfferMessage } from "_graphql/mutation/underwriter.place-it";
import toast from "react-hot-toast";
import { placeItClient } from "_graphql";
import { useCallback } from "react";
import { ArchiveMessageThread, ArchiveMessageThreadVariables } from "_graphql/mutation/__generated__/ArchiveMessageThread";

export default function SendMessageContainer({
    open,
    setOpen,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useAppSearch();
    const { employee } = useCurrentUser();
    const offerId = searchParams?.id || "";

    const { offers, loading } = usePlaceItOfferMessages({
        offerMessagesId: offerId
    });

    const [sendMessage, { loading: sendMessageLoading }] = useMutation<SendTheadMessage, SendTheadMessageVariables>(sendPlaceItOfferMessage, {
        refetchQueries: ["Offer_messages"],
        client: placeItClient
    });

    const [archive, { loading: archiveMessageThreadLoading }] = useMutation<ArchiveMessageThread, ArchiveMessageThreadVariables>(archiveMessageThread, {
        refetchQueries: ["Offer_messages"],
        client: placeItClient
    });

    const handleArchive = useCallback(async () => {
        try {
            const { data } = await archive({
                variables: {
                    offerID: offerId,
                    email: employee?.employee_email || "",
                }
            });
            if (data?.archiveMessageThread) {
                toast.success("Message thread archived successfully");
            }
        } catch (error) {
            console.log(error);
        }
    }, [archive, offerId, employee?.employee_email]);

    const handleSubmit = useCallback(async (values: { message: string }) => {
        try {
            const { data } = await sendMessage({
                variables: {
                    offerId,
                    message: values.message,
                    brokerFirstname: employee?.employee_first_name || "",
                    brokerLastname: employee?.employee_last_name || "",
                }
            });

            if (data?.sendTheadMessage?.message) {
                toast.success(data.sendTheadMessage.message);
            }

            form.resetForm();
        } catch (error) {
            console.log(error);
        }
    }, [sendMessage, offerId, employee?.employee_first_name, employee?.employee_last_name]);

    const form = useFormik({
        initialValues: {
            message: ""
        },
        onSubmit: handleSubmit
    });

    const renderActions = useCallback(() => (
        <>
            <OfficeButton
                size="sm"
                disabled={sendMessageLoading}
                onClick={wrapClick(form.handleSubmit)}
            >
                {sendMessageLoading ? "Sending..." : "Send"}
            </OfficeButton>
            <OfficeButton
                size="sm"
                disabled={archiveMessageThreadLoading}
                onClick={wrapClick(handleArchive)}
            >
                {archiveMessageThreadLoading ? "Archiving..." : "Archive"}
            </OfficeButton>
        </>
    ), [sendMessageLoading, form.handleSubmit, archiveMessageThreadLoading, handleArchive]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            loading={loading}
            title="Offer Message Thread"
            description=""
            renderActions={renderActions}
        >
            <MessageView offers={offers} form={form} />
        </Modal>
    );
}
