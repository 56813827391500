import {
    RegularSelectInput,
    RichEditor,
    TextInput,
} from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC } from "react";



type Props = {
    form: FormikProps<any>;
};

const TreatyClaimForm: FC<Props> = ({ form }) => {
    return (
        <div className="h-full flex-1 flex flex-col no-scrollbar">
            <div className="space-y-6 divide-y flex-1 overflow-y-scroll p-6 divide-gray-200">
                <div className="grid grid-cols-4 gap-4 bg-gray-200 border border-dashed border-gray-400 rounded-lg p-3">
                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Reinsured
                        </span>
                        <div className="mt-1 block w-full sm:text-sm font-medium">
                            {form?.values?.treaty?.treaty_program?.insurer?.insurer_company_name || "N/A"}
                        </div>
                    </div>
                    <div className="col-span-2">
                        <span className="block text-sm font-light text-gray-700">
                            Reinsured Email
                        </span>
                        <div className="mt-1 block w-full sm:text-sm font-medium">
                            {form?.values?.treaty?.insurer?.insurer_company_email || "N/A"}
                        </div>
                    </div>
                </div>
                <div className="pt-3 space-y-6">
                    <div className="space-y-4">
                        <div className="grid grid-cols-2 gap-2">
                            <div>
                                <TextInput
                                    type="text"
                                    id={`policy_number`}
                                    disabled
                                    label="Policy Number"
                                    placeholder="e.g. NCA-123-6457373"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="text"
                                    id={`claim_number`}
                                    label="Claim Number"
                                    placeholder="e.g. NCA-123-6457373"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="date"
                                    id={`date_of_loss`}
                                    label="Date of Loss"
                                    placeholder="e.g. NCA-123-6457373"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="text"
                                    id={`insured`}
                                    label="Insured"
                                    placeholder="e.g. Kwame Nkrumah"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div className="col-span-2">
                                <TextInput
                                    type="text"
                                    id={`claim_paid`}
                                    label="Claim Paid"
                                    placeholder="e.g. 1000000"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div>
                                <RegularSelectInput
                                    options={(JSON.parse(form?.values?.treaty?.layer_limit || "[]") || []).map((layer: any, i: number) => ({
                                        value: i + 1,
                                        label: `Layer ${i + 1}`,
                                    }))}
                                    id={`layer_number`}
                                    label="Layer Number"
                                    placeholder="Select Layer"
                                    required={true}
                                    {...form}
                                />
                            </div>
                            <div>
                                <TextInput
                                    type="number"
                                    id={`expected_deductible`}
                                    label="Deductible"
                                    placeholder="e.g. 1200"
                                    required={true}
                                    {...form}
                                />
                            </div>
                        </div>

                        {/* Claim Details Section */}
                        <div className="pt-3">
                            <span className="text-xs font-light">Claim Details</span>
                            <div className="grid grid-cols-2 gap-2">
                                <div>
                                    <TextInput
                                        type="number"
                                        step={0.01}
                                        id={`treaty_claim_details.third_party_claim_amount`}
                                        label="Plus(+) Third Party Claim Amount"
                                        placeholder="Third Party Claim Amount"
                                        {...form}
                                    />
                                </div>
                                <div>
                                    <TextInput
                                        type="number"
                                        step={0.01}
                                        id={`treaty_claim_details.survey_fees`}
                                        label="Plus(+) Third Survey fees"
                                        placeholder="Third Survey fees"
                                        {...form}
                                    />
                                </div>
                                {/* Additional Claim Details... */}
                                <div>
                                    <TextInput
                                        type="number"
                                        step={0.01}
                                        id={`treaty_claim_details.legal_fees`}
                                        label="Plus(+) Legal fees"
                                        placeholder="Legal fees"
                                        {...form}
                                    />
                                </div>
                                <div>
                                    <TextInput
                                        type="number"
                                        step={0.01}
                                        id={`treaty_claim_details.salvage`}

                                        label="Minus(-) Salvage"
                                        placeholder="Salvage"
                                        {...form}
                                    />
                                </div>
                                <div>
                                    <TextInput
                                        type="text"
                                        // step={0.01}
                                        id={`treaty_claim_details.company_name`}
                                        label="Company name"
                                        placeholder="Company name"
                                        {...form}
                                    />
                                </div>
                                <div>
                                    <TextInput
                                        type="number"
                                        step={0.01}
                                        id={`treaty_claim_details.net_liability_amount`}
                                        label="Plus(+) Net liability amount"
                                        placeholder="Net liability amount"
                                        {...form}
                                    />
                                </div>

                            </div>
                            <div className="pt-3">
                                <div className="grid grid-cols-2 gap-2">
                                    <div className="col-span-2">
                                        <RichEditor id={`treaty_comment`}
                                            label="Claim Comment"
                                            placeholder="Comment"
                                            required={true}
                                            {...form} />
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TreatyClaimForm;
