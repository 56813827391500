import clsx from "clsx";
import { FC } from "react";
import BarLoader from "react-spinners/BarLoader";

interface LoaderProps {
  color?: string;
  className?: string;
  text?: string;
}

const Loader: FC<LoaderProps> = ({ color = "#2563EB", className, text = "Loading details..." }) => {
  return (
    <div className={clsx("flex-1 flex flex-col space-y-6 items-center justify-center", className)}>
      <span className="text-gray-600 text-sm">{text}</span>
      <BarLoader
        color={color}
        loading={true}
        cssOverride={{ width: "30%" }}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Loader;
