import { LanguagePicker, Modal, PDFRenderer } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import config from "config";
import { useFormik } from "formik";
import { classNames } from "utils";
import { wrapClick } from "utils";
import { useState } from "react";



const tabs = [
  {
    name: "Placing Slip",
    href: "PLACING_SLIP",
  },
  {
    name: "Supporting Documents",
    href: "SUPPORTING_DOCUMENTS",
  }
]

export default function ViewOfferSlipContainer({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
}) {
  const [orderStatus, setOrderStatus] = useState("PLACING_SLIP");
  const searchParams = useSearch<LocationGenerics>();
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      size="6xl"
      title="Offer Details"
      hidePadding
      description="Documents related to the offer are shown below"
    >
      <div className="block">
        <div className="border-b border-shade-500 bg-card-500 px-6">
          <nav className="-mb-px flex space-x-8 items-center" aria-label="Tabs">
            {tabs.map((_orderStatus) => (
              <button
                key={_orderStatus.name}
                onClick={wrapClick(() => setOrderStatus(_orderStatus.href))}
                className={classNames(
                  orderStatus === _orderStatus.href
                    ? "border-gray-200 text-primary-600"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200",
                  "whitespace-nowrap flex py-4 px-1 border-b-2 disabled:cursor-not-allowed font-medium text-sm"
                )}
                aria-current={
                  orderStatus === _orderStatus.href ? "page" : undefined
                }
              >
                {_orderStatus.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      {/* <div className="mb-3">
        {`${config.asset.placeItUri}/generate-broker-placing-slip/${searchParams?.id}`}
        {`${config.asset.placeItUri}/generate-broker-supporting-docs/${searchParams?.id}`}
      </div> */}
      {orderStatus === "PLACING_SLIP" && <PDFRenderer
        url={`${config.asset.placeItUri}/generate-broker-placing-slip/${searchParams?.id}`}
      />}
      {orderStatus === "SUPPORTING_DOCUMENTS" && <PDFRenderer
        url={`${config.asset.placeItUri}/generate-broker-supporting-docs/${searchParams?.id}`}
      />}
    </Modal>
  );
}
