import { FC } from "react";
import TableHeaderComponent from "./table-header";
import { useUrlState } from "../../utils";
import TableBodyComponent from "./table-body";
import { TableColumn, TColumn } from "../../utils/types";
import TableGridBodyComponent from "./table-grid-body";
import { TableEmpty } from "./table-empty";
import { Show } from "components/core";

interface TableComponentProps<TData = any> {
  title: string;
  columns: TableColumn<TColumn>;
  data: TData[];
  loading?: boolean;
  isRefetching?: boolean;
  renderColumns?: FC<TData>;
  renderItem?: FC<TData>;
  renderGridItem?: FC<TData>;
  renderFilter?: FC<{
    filterOpen: boolean;
    setFilterOpen: (val: boolean) => void;
  }>;
  renderExport?: FC<{
    exportOpen: boolean;
    setExportOpen: (val: boolean) => void;
  }>;
  renderLoader?: FC;
  renderGridLoader?: FC;
  renderHeaderItems?: FC;
  refetch: () => void;
  hasSearch?: boolean;
  defaultView?: "grid" | "list";
  pagination?: FC;
  showHeader?: boolean;
  emptyMessage?: string;
}

const Table: FC<TableComponentProps> = ({
  title,
  renderFilter,
  renderExport,
  renderGridItem,
  renderItem,
  refetch,
  renderHeaderItems,
  hasSearch,
  isRefetching,
  defaultView = "list",
  data,
  loading,
  renderLoader,
  renderGridLoader,
  columns,
  pagination,
  showHeader = true,
  emptyMessage
}) => {
  const [viewType] = useUrlState("viewType");
  const hasGridMode = !!renderGridItem;
  const isEmpty = !loading && (data?.length || 0) === 0;
  return (
    <div className="h-full flex flex-col overflow-hidden w-full">
      <div className="px-4 sm:px-6 flex-shrink-0 w-full">
        <TableHeaderComponent
          title={title}
          renderFilter={renderFilter}
          renderExport={renderExport}
          gridable={hasGridMode}
          listable={true}
          refetch={refetch}
          renderHeaderItems={renderHeaderItems}
          hasSearch={hasSearch}
          loading={isRefetching}
          defaultView={defaultView}
        />
      </div>

      <div className="flex-1 min-h-0 relative my-2 w-full overflow-auto">
        <Show if={!isEmpty}>
          <>
            {viewType === "grid" && hasGridMode ? (
              <div className="h-full w-full px-4">
                <TableGridBodyComponent
                  data={data}
                  loading={loading}
                  renderLoader={renderGridLoader}
                  renderItem={renderGridItem}
                />
              </div>
            ) : (
              <TableBodyComponent
                data={data}
                loading={loading}
                renderLoader={renderLoader}
                isEmpty={isEmpty}
                renderItem={renderItem}
                showHeader={showHeader}
                columns={columns}
              />
            )}
          </>
        </Show>
        <Show if={isEmpty}>
          <TableEmpty message={emptyMessage || "No data available"} />
        </Show>
      </div>

      <div className="px-4 sm:px-6 flex-shrink-0">
        {pagination?.({})}</div>
    </div>
  );
};

export default Table;
