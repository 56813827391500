import { useMutation } from "@apollo/client";
import toast from "react-hot-toast";
import { PopUp } from "components";
import { useSearch } from "react-location";
import { LocationGenerics } from "router/location";
import { DeleteClaimCreated, DeleteClaimCreatedVariables } from "_graphql/mutation/__generated__/DeleteClaimCreated";
import { deleteTreatyClaim } from "_graphql/mutation/treaty-claims";

export default function RemoveContainer({
  open,
  setOpen,
  refetch,
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  refetch?: () => void;
}) {
  const searchParams = useSearch<LocationGenerics>();
  const [remove, { loading }] = useMutation<
    DeleteClaimCreated,
    DeleteClaimCreatedVariables
  >(deleteTreatyClaim, {
    refetchQueries: ["TreatyClaims"],
  });

  const handleSubmit = async () => {
    // await remove({
    //   variables: {
    //     treatyClaimId: searchParams?.id || "",
    //   },
    // }).then(({ data }) => {
    //   if (data?.deleteClaimCreated) {
    //     toast.success("Claim Removed Successfully");
    //     refetch?.();
    //     setOpen?.(false);
    //   } else {
    //     toast.error("Could not remove Claim");
    //   }
    // });
  };

  return (
    <PopUp
      open={open}
      setOpen={setOpen}
      title="Remove Participant"
      cancelText="No, Don't Remove Participant"
      description="This action would completely remove Participant details from this configuration"
      renderActions={() => (
        <>
          <button
            type="button"
            disabled={loading}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={handleSubmit}
          >
            {loading ? "Removing Participant..." : "Remove Participant"}
          </button>
        </>
      )}
    />
  );
}
