import _ from "lodash";
import { FC } from "react";
import { TColumn, TableColumn } from "../../utils/types";
import { Show } from "components/core";

interface TableBodyComponentProps<TData = any> {
  data: TData[];
  columns: TableColumn<TColumn>;
  renderItem?: FC<TData>;
  renderLoader?: FC;
  loading?: boolean;
  isEmpty?: boolean;
  showHeader: boolean;
}

const TableBodyComponent: FC<TableBodyComponentProps> = ({
  data,
  renderItem,
  loading,
  renderLoader,
  columns,
  isEmpty,
  showHeader,
}) => {
  return (
    <div className="h-full overflow-auto">
      <div className="w-full align-middle px-6">
        <table
          className="w-full table-fixed divide-y divide-shade-500 border-x border-shade-500"
          style={{ borderSpacing: 0 }}
        >
          <Show if={!isEmpty && showHeader}>
            <thead className="bg-gray-50">
              <tr>
                {columns?.map((columnItem, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="sticky top-0 border-y border-shade-500 bg-card-500 px-2 py-3 text-left text-xs font-light text-gray-100 uppercase tracking-wider truncate"
                    style={{ width: columnItem.width || `${100 / columns.length}%` }}
                  >
                    <div className="truncate">
                      {columnItem?.name}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
          </Show>
          <tbody className="bg-card-500 divide-y divide-shade-500">
            {loading
              ? renderLoader?.({}) ?? (
                <tr>
                  {columns?.map((col, idx) => (
                    <td
                      key={idx}
                      className="px-6 py-4 text-sm text-gray-500 border-b border-shade-500"
                      style={{ width: col.width || `${100 / columns.length}%` }}
                    >
                      <div className="bg-gray-500 h-3 rounded-md w-full animate-pulse" />
                    </td>
                  ))}
                </tr>
              )
              : data?.map(
                (item, index) =>
                  renderItem?.(item) ?? (
                    <tr key={index}>
                      {columns?.map((col, idx) =>
                        col?.render ? (
                          <td
                            key={idx}
                            className="p-2 text-sm text-gray-500 border-b border-shade-500"
                            style={{ width: col.width || `${100 / columns.length}%` }}
                          >
                            <div className="break-words">
                              <col.render {...item} />
                            </div>
                          </td>
                        ) : (
                          <td
                            key={idx}
                            className="px-2 py-4 text-sm text-gray-500 border-b border-shade-500"
                            style={{ width: col.width || `${100 / columns.length}%` }}
                          >
                            <div className="break-words">
                              {_.get(item, (col.accessor as string) ?? "N/A")}
                            </div>
                          </td>
                        )
                      )}
                    </tr>
                  )
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableBodyComponent;
