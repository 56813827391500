import { ChatBubbleLeftEllipsisIcon } from "@heroicons/react/24/outline";
import { Offer_messages_offer_messages } from "_graphql/queries/__generated__/Offer_messages";
import { Avatar, RichEditor, TextArea } from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import moment from "moment";
import { FC, useEffect, useRef } from "react";

type Props = {
  offers: (Offer_messages_offer_messages | null)[]
  form: FormikProps<any>
};

const MessageView: FC<Props> = ({ offers, form }) => {
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [offers]);

  return (
    <div className="flex flex-col flex-1 h-[500px] overflow-clip">
      <div className="flex h-full flex-1 justify-between overflow-y-scroll no-scrollbar flex-col">
        {offers?.length < 1 ? (
          <div className="flex-1 h-full flex flex-col justify-center items-center space-y-2">
            <ChatBubbleLeftEllipsisIcon className="h-12 w-12 text-gray-400" />
            <p className="text-gray-500 font-medium">No messages yet</p>
          </div>
        ) : (
          <div className="flex-1 overflow-y-auto no-scrollbar">
            <ul role="list" className="space-y-6 px-2">
              {offers.map((activityItem, activityItemIdx) => (
                <li key={activityItem?.uuid}>
                  <div className="relative pb-4">
                    {activityItemIdx !== offers.length - 1 && (
                      <span
                        aria-hidden="true"
                        className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                      />
                    )}
                    <div className="relative flex items-start space-x-4">
                      <div className="relative">
                        <Avatar
                          alt={activityItem?.sender_name || ""}
                          // src={activityItem?.sender_name || ""}
                          size="md"
                        // className="ring-4 ring-white"
                        />
                        <span className="absolute -right-1 -bottom-0.5 rounded-tl bg-white px-0.5 py-px">
                          <ChatBubbleLeftEllipsisIcon
                            aria-hidden="true"
                            className="h-5 w-5 text-gray-400"
                          />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1">
                        <div className="flex items-center space-x-2">
                          <span className="font-medium text-gray-900">
                            {activityItem?.sender_name}
                          </span>
                          <span className="text-sm text-gray-500">
                            •  {moment(activityItem?.timestamp).calendar(null, {
                              sameDay: '[Today at] HH:mm A',
                              lastDay: '[Yesterday at] HH:mm A',
                              lastWeek: 'dddd [at] HH:mm A',
                              nextWeek: 'dddd [at] HH:mm A',
                              sameElse: 'DD/MM/YYYY [at] HH:mm A'
                            })}
                          </span>
                        </div>
                        <div className="mt-2 text-sm text-gray-700 bg-gray-50 rounded-lg p-4">
                          <p dangerouslySetInnerHTML={{ __html: activityItem?.message || "" }} />
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <div ref={messagesEndRef} />
          </div>
        )}

      </div>
      <div className="flex w-full pt-4 border-t">
        <RichEditor
          id="message"
          label=""
          {...form}
          // className="w-full focus:ring-primary-500 focus:border-primary-500"
          placeholder="Type your message here..."
        />
      </div>
    </div>
  );
};

export default MessageView;
