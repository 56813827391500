/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Claim_status {
  CLAIMED = "CLAIMED",
  UNCLAIMED = "UNCLAIMED",
}

export enum DeleteStatusEnum {
  DELETED = "DELETED",
  NOTDELETED = "NOTDELETED",
}

export enum Expiry_status {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
}

export enum FILTERBYDEDUCTION {
  NIC = "NIC",
  WHT = "WHT",
}

export enum NotificationType {
  DOCUMENT_SMS = "DOCUMENT_SMS",
  SYSTEM_BUSINESS = "SYSTEM_BUSINESS",
  SYSTEM_CLAIM = "SYSTEM_CLAIM",
  SYSTEM_EMAIL = "SYSTEM_EMAIL",
  SYSTEM_INSURER = "SYSTEM_INSURER",
  SYSTEM_OFFER = "SYSTEM_OFFER",
  SYSTEM_PLACEIT = "SYSTEM_PLACEIT",
  SYSTEM_REINSURER = "SYSTEM_REINSURER",
}

export enum Offer_status {
  CLOSED = "CLOSED",
  OPEN = "OPEN",
  PENDING = "PENDING",
}

export enum Payment_status {
  PAID = "PAID",
  PARTPAYMENT = "PARTPAYMENT",
  UNPAID = "UNPAID",
}

export enum Sent_status {
  SENT = "SENT",
  UNSENT = "UNSENT",
}

export enum TreatyAccountPeriod {
  FIRSTQUARTER = "FIRSTQUARTER",
  FOURTHQUARTER = "FOURTHQUARTER",
  SECONDQUARTER = "SECONDQUARTER",
  THIRDQUARTER = "THIRDQUARTER",
}

export enum TreatyType {
  NONPROPORTIONAL = "NONPROPORTIONAL",
  PROPORTIONAL = "PROPORTIONAL",
}

export interface BrokerDistributionList {
  broke_distribution?: (BrokerList | null)[] | null;
  treaty_id?: string | null;
}

export interface BrokerList {
  re_broker_id?: string | null;
  re_associate_ids?: (string | null)[] | null;
}

export interface Claim_data {
  offer_id: string;
  claim_amount: string;
  claim_date: string;
  claim_comment?: string | null;
  currency?: string | null;
  rate?: number | null;
}

export interface ClaimsFilter {
  offer_id?: string | null;
}

export interface Convert_type {
  currency?: string | null;
  rate?: number | null;
}

export interface DashboardOverviewInput {
  filter_by: (string | null)[];
  for?: string | null;
  from?: any | null;
  to?: any | null;
}

export interface Deductions {
  commission?: number | null;
  nic_levy?: number | null;
  withholding_tax?: number | null;
  brokerage?: number | null;
  participating_percentage?: number | null;
}

export interface EmailComponent {
  subject?: string | null;
  email_content?: string | null;
  copied_email?: (string | null)[] | null;
  attachments?: (string | null)[] | null;
}

export interface Email_data {
  offer_id: string;
  reinsurer_id?: string | null;
  subject?: string | null;
  copied_emails?: (string | null)[] | null;
  message_content?: string | null;
  attachment?: string[] | null;
}

export interface Employee_data {
  employee_first_name: string;
  employee_last_name: string;
  employee_phonenumber: string;
  employee_email: string;
  employee_position: string;
}

export interface EndorsementFilters {
  offset: number;
  limit: number;
  search?: string | null;
  insurer_id?: string | null;
}

export interface EndorsementParticipationAndDeductions {
  participating_percentage?: number | null;
  wht?: number | null;
  nic?: number | null;
  brokerage?: number | null;
  commission?: number | null;
}

export interface ExtendedTreatyDetails {
  type?: TreatyType | null;
  proportional_detail?: TreatyProportionalDetailInput | null;
  np_detail?: TreatyNPDetailInput | null;
}

export interface Insurer_associate_data {
  insurersinsurer_id?: string | null;
  rep_first_name: string;
  rep_last_name: string;
  rep_primary_phonenumber: string;
  rep_secondary_phonenumber?: string | null;
  rep_email: string;
  position: string;
}

export interface Insurer_data {
  insurer_company_name: string;
  insurer_company_email: string;
  insurer_company_website?: string | null;
  street: string;
  suburb: string;
  region: string;
  country: string;
}

export interface LimitData {
  treaty_id?: string | null;
  limit?: string | null;
}

export interface NPTreatyData {
  email_component?: EmailComponent | null;
  limit?: number | null;
  m_and_d_premium?: number | null;
  total_participation_percentage?: number | null;
  installment_type?: number | null;
  layer?: number | null;
  treaty_id?: number | null;
  participant_id?: number | null;
  uuid?: string | null;
}

export interface NotificationFilter {
  notificationType?: NotificationType | null;
  notificationStartDate?: any | null;
  notificationEndDate?: any | null;
  search?: string | null;
}

export interface OfferClaimParticipantPaymentInput {
  offer_claim_participant: string;
  claim_share_payment_amount: number;
  payment_details: string;
  payment_comment?: string | null;
  payment_date: any;
}

export interface OfferFilters {
  insurer_id?: string | null;
  offset?: number | null;
  limit?: number | null;
  search?: string | null;
  status?: (string | null)[] | null;
  claim_status?: (string | null)[] | null;
  payment_status?: (string | null)[] | null;
  year?: string | null;
}

export interface OfferFiltersReinsurer {
  reinsurer_id?: string | null;
  offset?: number | null;
  limit?: number | null;
  status?: (string | null)[] | null;
  search?: string | null;
  year?: string | null;
}

export interface OfferPaymentInput {
  offer_id?: string | null;
}

export interface Offer_data {
  class_of_business_id: string;
  insurer_id: string;
  employee_id: string;
  rate: number;
  commission: number;
  brokerage: number;
  facultative_offer: number;
  sum_insured: number;
  premium: number;
  policy_number?: string | null;
  insured_by: string;
  period_of_insurance_from: any;
  period_of_insurance_to: any;
  currency: string;
  offer_comment?: string | null;
  offer_details: string;
}

export interface Offer_edit_extra_charge_data {
  offer_id?: string | null;
  reinsurer_data: Reinsurer_extra_charge;
}

export interface Offer_extra_charge_data {
  offer_id?: string | null;
  reinsurer_data: Reinsurer_extra_charge[];
}

export interface Offer_participant_payment_details {
  offer_participant_payment_id: string;
  paid_details: string;
  payment_comment: string;
}

export interface Offer_participant_payment_update {
  offer_id: string;
  offer_payment_id: string;
  offer_participants: (Offer_participant_payment_details | null)[];
}

export interface Offer_payment_data {
  offer_id: string;
  payment_amount: number;
  payment_details: string;
  Offer_payment_comment: string;
}

export interface Offer_payment_update_data {
  offer_payment_id: string;
  offer_id: string;
  payment_amount: number;
  payment_details: string;
  date_of_receipt?: any | null;
  Offer_payment_comment: string;
  payment_status?: string | null;
  auto_payment_receipt?: boolean | null;
}

export interface Pagination {
  offset?: number | null;
  limit?: number | null;
}

export interface PaymentDetails {
  payment_amount: number;
  treaty_id?: string | null;
  treaty_account_id?: string | null;
  payment_id?: string | null;
  payment_details: string;
  payment_comment?: string | null;
  payment_status: string;
  uuid?: string | null;
  installment_type?: number | null;
  auto_payment_receipt?: boolean | null;
}

export interface PortFolioEntry {
  withdrawal_percentage?: number | null;
  withdrawal_loss_percentage?: number | null;
  assumption_percentage?: number | null;
  assumption_loss_percentage?: number | null;
}

export interface PrevYearConfigurationData {
  outgoing_premium_portfolio?: number | null;
  outgoing_loss_portfolio?: number | null;
}

export interface Query_data {
  client_type?: string | null;
  from?: any | null;
  to?: any | null;
  payment_from?: any | null;
  payment_to?: any | null;
  payment_status?: (string | null)[] | null;
  offer_status?: (string | null)[] | null;
  period_status?: (string | null)[] | null;
  reinsurer_id?: (string | null)[] | null;
  insurer_id?: (string | null)[] | null;
  business_id?: (string | null)[] | null;
  convert_from?: (Convert_type | null)[] | null;
  convert_to?: string | null;
  deduction?: FILTERBYDEDUCTION | null;
  queryOnPayment?: boolean | null;
}

export interface ReBrokerAddressInput {
  street?: string | null;
  city?: string | null;
  region?: string | null;
  country?: string | null;
  re_primary_phone?: string | null;
  re_secondary_phone?: string | null;
}

export interface ReBrokerAssociateInput {
  re_broker_assoc_first_name?: string | null;
  re_broker_assoc_last_name?: string | null;
  re_broker_assoc_primary_phone?: string | null;
  re_broker_assoc_secondary_phone?: string | null;
  re_broker_assoc_position?: string | null;
  re_broker_assoc_email?: string | null;
  re_brokersre_broker_id?: string | null;
}

export interface ReBrokerInput {
  re_broker_name?: string | null;
  re_broker_email?: string | null;
  re_broker_website?: string | null;
  re_broker_address?: ReBrokerAddressInput | null;
}

export interface ReBrokerParticipationPercentageInput {
  treaty_id?: string | null;
  treaty_type?: TreatyType | null;
  re_broker_treaties_participation_id?: string | null;
  share_percentage?: number | null;
  admin_percentage?: number | null;
}

export interface ReinsurerParticipationUpdate {
  treaty_participant_deduction_id?: (string | null)[] | null;
  participant_id?: string | null;
  deductions?: Deductions | null;
}

export interface Reinsurer_data {
  re_company_name: string;
  re_company_email: string;
  re_company_website?: string | null;
  street: string;
  suburb: string;
  region: string;
  country: string;
}

export interface Reinsurer_extra_charge {
  reinsurer_id?: string | null;
  reinsurer_participant_id?: string | null;
  nic_levy: number;
  withholding_tax: number;
  agreed_brokerage_percentage: number;
  agreed_commission: number;
}

export interface Reinsurer_rep_data {
  reinsurersreinsurer_id?: string | null;
  rep_first_name: string;
  rep_last_name: string;
  rep_primary_phonenumber: string;
  rep_secondary_phonenumber?: string | null;
  rep_email: string;
  position: string;
}

export interface Reinsurer_representative_data {
  reinsurer_id?: string | null;
  representatives_ids: string[];
}

export interface TreatyAccountData {
  treatiestreaty_id: string;
  account_year?: string | null;
  account_periods?: TreatyAccountPeriod | null;
  participant_deduction_id?: string | null;
  exchange_rate?: string | null;
  surpulus_data?: (TreatyAccountSurpulusInput | null)[] | null;
}

export interface TreatyAccountSurpulusInput {
  surpulus_uuid: string;
  gross_premium: number;
  claim_settled?: number | null;
  cash_loss?: number | null;
  account_deduction_id?: string | null;
}

export interface TreatyAssociateDeductionData {
  commission?: number | null;
  brokerage: number;
  nic_levy: number;
  withholding_tax: number;
  treaty_period_from: any;
  treaty_period_to: any;
  treaty_programstreaty_program_id: string;
}

export interface TreatyClaimData {
  policy_number?: string | null;
  claim_number?: string | null;
  insured_name: string;
  date_of_loss?: any | null;
  claim_date?: any | null;
  claim_paid: number;
  layer_number: number;
  expected_deductible?: number | null;
  treaty_comment?: string | null;
  treaty_claim_details?: string | null;
}

export interface TreatyData {
  treaty_details?: string | null;
  currency?: string | null;
  order_hereon?: number | null;
  treaty_reference?: string | null;
  kek_reference?: string | null;
  insurersinsurer_id?: string | null;
  treaty_programstreaty_program_id?: string | null;
  treaty_associate_deductionstreaty_associate_deduction_id?: string | null;
  layer_limit?: string | null;
  installment_type?: number | null;
  m_and_d_premium?: number | null;
  treaty_extended_details?: ExtendedTreatyDetails | null;
}

export interface TreatyFilters {
  insurer_id?: string | null;
  broker_id?: string | null;
  offset?: number | null;
  limit?: number | null;
  search?: string | null;
  treaty_expiry_status?: (string | null)[] | null;
  treaty_claim_status?: (string | null)[] | null;
  treaty_payment_status?: (string | null)[] | null;
  treaty_program_type?: (string | null)[] | null;
}

export interface TreatyNPDetailInput {
  egrnpi?: number | null;
  adjustment_created?: boolean | null;
}

export interface TreatyPaymentDistribution {
  payment_details?: string | null;
  payment_comment?: string | null;
  participant_payment_id?: string | null;
}

export interface TreatyPaymentFilter {
  treaty_id?: string | null;
}

export interface TreatyProgramData {
  treaty_type?: TreatyType | null;
  treaty_name: string;
  treaty_details?: string | null;
  insurersinsurer_id?: string | null;
}

export interface TreatyProgramFilters {
  insurer_id?: string | null;
  offset?: number | null;
  limit?: number | null;
  search?: string | null;
  treaty_type?: (string | null)[] | null;
}

export interface TreatyProportionalDetailInput {
  treaty_id?: string | null;
  profit_commission?: number | null;
  re_mgmt_expense?: number | null;
  portfolio_entry?: PortFolioEntry | null;
  prev_year_configuration?: PrevYearConfigurationData | null;
  ernpi?: number | null;
  overall_gross_premium?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
