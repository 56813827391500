import React from 'react'
import { Link } from 'react-location'

type Props = {
    notification: any
    parsedNotification: any
}

const ClaimNotificationComponent = ({ notification, parsedNotification }: Props) => {
    const particiupants = parsedNotification?.claim_participants || [];
    const claim = parsedNotification?.claim_data;
    return (
        <div className="max-w-5xl mx-auto px-6 py-8">
            <div className="bg-amber-50 border-l-4 border-amber-400 p-4 mb-6 rounded-r">
                <p className="text-amber-700">
                    {JSON.parse(notification?.system_notification?.notification_content || "{}").message}
                </p>
            </div>

            <div className="mb-6 flex items-center flex-wrap gap-6">
                <div className="flex items-center">
                    <span className="text-gray-600">Offer:</span>
                    <strong className="ml-2 text-gray-900">{parsedNotification?.policy_number}</strong>
                </div>
                <div className="flex items-center">
                    <span className="text-gray-600">Offer status:</span>
                    <span
                        className={`ml-2 px-3 py-1 rounded-full text-sm font-medium ${parsedNotification?.offer_status === "OPEN"
                            ? "bg-blue-100 text-blue-800"
                            : parsedNotification?.offer_status === "PENDING"
                                ? "bg-amber-100 text-amber-800"
                                : "bg-emerald-100 text-emerald-800"
                            }`}
                    >
                        {parsedNotification?.offer_status}
                    </span>
                </div>
            </div>

            <div className="mb-8">
                <h2 className="text-lg font-semibold text-gray-900 mb-4">Claim Details</h2>
                <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                    <div className="grid grid-cols-2 gap-4 p-6">
                        <div>
                            <p className="text-sm text-gray-600">Claim amount</p>
                            <p className="text-gray-900 font-medium mt-1">
                                {parsedNotification?.currency} {claim?.claim_amount}
                            </p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Claim date</p>
                            <p className="text-gray-900 font-medium mt-1">
                                {new Date(claim?.claim_date).toDateString()}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {particiupants.length > 0 && (
                <div className="mb-8">
                    <h2 className="text-lg font-semibold text-gray-900 mb-4">Participants Share</h2>
                    <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
                        <table className="w-full">
                            <thead>
                                <tr className="bg-gray-50">
                                    <th className="text-left py-3 px-6 text-sm font-medium text-gray-600">Reinsurer</th>
                                    <th className="text-left py-3 px-6 text-sm font-medium text-gray-600">Share</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200">
                                {particiupants.map((payment: any, key: number) => (
                                    <tr key={key}>
                                        <td className="py-4 px-6 text-sm text-gray-900">{payment.reinsurer}</td>
                                        <td className="py-4 px-6 text-sm text-gray-900">
                                            {parsedNotification?.currency} {payment.claim_share}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}

            <div className="mb-8">
                <h2 className="text-lg font-semibold text-gray-900 mb-4">Created Information</h2>
                <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                    <div className="grid grid-cols-2 gap-4 p-6">
                        <div>
                            <p className="text-sm text-gray-600">Created By</p>
                            <p className="text-gray-900 font-medium mt-1">
                                {parsedNotification?.employee_first_name} {parsedNotification?.employee_first_name}
                            </p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Created on</p>
                            <p className="text-gray-900 font-medium mt-1">{parsedNotification?.offer_created_date}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Class of Business</p>
                            <p className="text-gray-900 font-medium mt-1">{parsedNotification?.business_name}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-8">
                <h2 className="text-lg font-semibold text-gray-900 mb-4">Offer Details</h2>
                <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                    <div className="grid grid-cols-2 gap-6 p-6">
                        <div>
                            <p className="text-sm text-gray-600">Policy #</p>
                            <p className="text-gray-900 font-medium mt-1">{parsedNotification?.policy_number}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Insured By</p>
                            <p className="text-gray-900 font-medium mt-1">{parsedNotification?.insured_by}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Period of Insurance</p>
                            <p className="text-gray-900 font-medium mt-1">{parsedNotification?.period_of_insurance}</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Reinsured</p>
                            <p className="text-gray-900 font-medium mt-1">{parsedNotification?.insurer_company_name}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mb-8">
                <h2 className="text-lg font-semibold text-gray-900 mb-4">Offer Information</h2>
                <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                    <div className="grid grid-cols-2 gap-6 p-6">
                        <div>
                            <p className="text-sm text-gray-600">Fac. Premium</p>
                            <p className="text-gray-900 font-medium mt-1">
                                {parsedNotification?.currency} {parsedNotification?.fac_premium}
                            </p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Brokerage</p>
                            <p className="text-gray-900 font-medium mt-1">{parsedNotification?.brokerage}%</p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Fac. Sum Insured</p>
                            <p className="text-gray-900 font-medium mt-1">
                                {parsedNotification?.currency} {parsedNotification?.fac_sum_insured}
                            </p>
                        </div>
                        <div>
                            <p className="text-sm text-gray-600">Commission</p>
                            <p className="text-gray-900 font-medium mt-1">{parsedNotification?.commission}%</p>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <Link
                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 transition-colors"
                >
                    Go to Claims
                </Link>
            </div>
        </div>
    )
}

export default ClaimNotificationComponent