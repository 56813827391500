import {
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { FetchTreatyAccounts_fetchTreatyAccounts } from "_graphql/queries/__generated__/FetchTreatyAccounts";
import { Treaty_treaty } from "_graphql/queries/__generated__/Treaty";
import { CurrencyPicker } from "components/core";
import { FormikProps } from "formik";
import _ from "lodash";
import { FC, useEffect, useState } from "react";

export interface DuplicateTreatyCurrencyFormProps {
  form: FormikProps<any>;
  treaty?: Treaty_treaty | null;
  accounts?: (FetchTreatyAccounts_fetchTreatyAccounts | null)[];
}

const DuplicateTreatyCurrencyForm: FC<DuplicateTreatyCurrencyFormProps> = ({
  form,
  accounts,
}) => {
  const [selectedCurrencies, setSelectedCurrencies] = useState<any[]>([]);

  useEffect(() => {
    form.setFieldValue("currency", selectedCurrencies);
  }, [selectedCurrencies])

  return (
    <div className="max-w-3xl mx-auto">
      <div className="space-y-8">
        <div className="bg-amber-50 rounded-lg shadow-sm border border-amber-200 overflow-hidden">
          <div className="p-4 flex items-start space-x-4">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-amber-500 mt-1"
              aria-hidden="true"
            />
            <div className="flex-1">
              <h3 className="font-medium text-amber-800 mb-2">Existing Currency Duplications</h3>
              <div className="flex flex-wrap gap-2 mb-4">
                {accounts?.map((el, idx) => (
                  <span key={idx} className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-amber-100 text-amber-800">
                    {el?.currency}
                  </span>
                ))}
              </div>

              <div className="text-sm text-amber-700 space-y-4">
                <div>
                  <h4 className="font-medium mb-2">Requirements for Effective Treaty Cloning:</h4>
                  <ul className="list-disc list-inside space-y-1 ml-2">
                    <li>Create and allocate quotas for all reinsurers</li>
                    <li>Create and allocate quotas for all intermediaries</li>
                  </ul>
                </div>

                <div className="bg-amber-100/50 p-3 rounded-md">
                  <p className="font-medium">Important Note:</p>
                  <p>This action will automatically create Test Proportional entries for all selected currencies.</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="space-y-6">
            <div>
              <CurrencyPicker
                id="currency-select"
                label="Select Currencies"
                required
                placeholder="Choose currencies to duplicate"
                {...form}
                onChange={(e) => {
                  setSelectedCurrencies(prev => [...prev, e])
                  form.setFieldValue("currency", selectedCurrencies);
                }}
              // className="w-full"
              />
            </div>

            <div className="flex flex-wrap gap-2">
              {selectedCurrencies.map((currency, index) => (
                <span key={index} className="inline-flex items-center gap-x-1.5 rounded-full bg-blue-50 px-3 py-1.5 text-sm font-medium text-blue-700 transition-colors hover:bg-blue-100">
                  {currency}
                  <button
                    onClick={() => {
                      setSelectedCurrencies(prev => prev.filter((_, i) => i !== index))
                    }}
                    type="button"
                    className="group -mr-1 h-4 w-4 rounded-full hover:bg-blue-200/50"
                  >
                    <span className="sr-only">Remove</span>
                    <svg viewBox="0 0 14 14" className="h-4 w-4 stroke-blue-700/70 group-hover:stroke-blue-800">
                      <path d="M4 4l6 6m0-6l-6 6" strokeWidth="1.5" />
                    </svg>
                  </button>
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DuplicateTreatyCurrencyForm;
