import { FormikProps } from "formik";
import { FC, useMemo, useState } from "react";
import SummaryPage from "./summary";
import StepWizard from "./step-wizard";
import _ from "lodash";

export type FormItem = {
  name: string;
  description: string;
  accessor: string;
  FormComponent: FC<any>;
  SummaryComponent: FC<any>;
};

type StepFormBuilderProps = {
  formSteps: FormItem[];
  form: FormikProps<any>;
  setOpen?: (val: boolean) => void;
  initialStep?: number;
};

const StepFormBuilder: FC<StepFormBuilderProps> = ({
  formSteps,
  form,
  setOpen,
  initialStep,
}) => {
  const [step, setStep] = useState(() => initialStep || 0);
  const [lastStep, setLastStep] = useState(0);
  const filteredFormSteps = useMemo(
    () =>
      formSteps
        // .filter((_step) => Object.keys(form.values).includes(_step.accessor))
        .filter((_step) => !_.isEmpty(_.get(form.values, _step.accessor)))
        .map((_step, href) => ({
          ..._step,
          initialValues: _.get(form.values, _step.accessor),
          href,
        })),
    [Object.keys(form.values)]
  );
  // console.log(filteredFormSteps);
  const steps = useMemo(
    () =>
      [
        ...filteredFormSteps,
        { name: "Preview", FormComponent: SummaryPage, accessor: "summary" },
      ].map((rawStep, href) => ({ ...rawStep, href })),
    [filteredFormSteps]
  );

  const onCancel = () => {
    setStep(0);
    setLastStep(0);
    form.resetForm();
  };

  const handleNextStep = (_step: string) => (_values: any) => {
    // check if last step
    form.setFieldValue(_step, _values);
    setStep(step + 1);
    setLastStep(step === lastStep ? lastStep + 1 : lastStep);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleCancel = () => {
    form.resetForm();
    onCancel();
    setOpen?.(false);
  };

  const renderForm = () => {
    const { FormComponent, accessor } = steps[step || 0];

    const props = {
      values: form.values,
      handlePrevious: handlePreviousStep,
      handleCancel: handleCancel,
      handleStep: setStep,
      handleSubmit: form.handleSubmit,
      submitLoading: form.isSubmitting,
      parentForm: form,
      steps: filteredFormSteps,
      ...(accessor !== "summary"
        ? {
          handleNext: handleNextStep(accessor),
          initialValues: _.get(form.values, accessor),
        }
        : {}),
    };
    return <FormComponent {...props} />;
  };

  return (
    <div className="bg-white  shadow-xl flex flex-col flex-1 relative">
      {/* {JSON.stringify(form.errors, null, 2)} */}
      <div className="px-6 pt-6">
        <StepWizard
          steps={steps}
          step={step}
          setStep={setStep}
          lastStep={lastStep}
        />
      </div>
      <div className="flex flex-1 overflow-hidden">{renderForm()}</div>
    </div>
  );
};

export default StepFormBuilder;
