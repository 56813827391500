

import moment from 'moment'
import numeral from 'numeral'
import React from 'react'

type Props = {
    data: any
}

const TreatyClaimFormSummary = ({ data }: Props) => {
    return (
        <>
            {data?.treaties?.map((treaty: any, idx: number) => (
                <div key={"treaty-item-" + idx} className="border-2 border-dashed border-gray-300 dark:border-gray-600 rounded-md p-3">
                    {/* {JSON.stringify(treaty, null, 2)} */}
                    <div>
                        <div className="grid grid-cols-3 gap-6">

                            <div className="col-span-2">
                                <span className="block text-sm font-light text-gray-700">
                                    Company Name
                                </span>
                                <div className="mt-1 block w-full sm:text-sm">
                                    {treaty?.treaty_claim_details?.company_name || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className="block text-sm font-light text-gray-700">
                                    Claim Paid
                                </span>
                                <div className="mt-1 block w-full sm:text-sm">
                                    {numeral(treaty?.claim_paid).format("0,0.00") || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className="block text-sm font-light text-gray-700">
                                    Date of Loss
                                </span>
                                <div className="mt-1 block w-full sm:text-sm">
                                    {moment(treaty?.date_of_loss).format("LLL") || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className="block text-sm font-light text-gray-700">
                                    Deductible
                                </span>
                                <div className="mt-1 block w-full sm:text-sm">
                                    {numeral(treaty?.expected_deductible).format("0,0.00") || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className="block text-sm font-light text-gray-700">
                                    Layer Number
                                </span>
                                <div className="mt-1 block w-full sm:text-sm">
                                    {treaty?.layer_number || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className="block text-sm font-light text-gray-700">
                                    Plus(+) Third Party Claim Amount
                                </span>
                                <div className="mt-1 block w-full sm:text-sm">
                                    {numeral(treaty?.treaty_claim_details?.third_party_claim_amount).format("0,0.00") || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className="block text-sm font-light text-gray-700">Plus(+) Third Survey fees
                                </span>
                                <div className="mt-1 block w-full sm:text-sm">
                                    {numeral(treaty?.treaty_claim_details?.survey_fees).format("0,0.00") || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className="block text-sm font-light text-gray-700">
                                    Plus(+) Legal fees
                                </span>
                                <div className="mt-1 block w-full sm:text-sm">
                                    {numeral(treaty?.treaty_claim_details?.legal_fees).format("0,0.00") || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className="block text-sm font-light text-gray-700">Minus(-) Salvage

                                </span>
                                <div className="mt-1 block w-full sm:text-sm">
                                    {numeral(treaty?.treaty_claim_details?.salvage).format("0,0.00") || "N/A"}
                                </div>
                            </div>
                            <div>
                                <span className="block text-sm font-light text-gray-700">Plus(+) Net liability amount
                                </span>
                                <div className="mt-1 block w-full sm:text-sm">
                                    {numeral(treaty?.treaty_claim_details?.net_liability_amount).format("0,0.00") || "N/A"}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}

        </>

    )
}

export default TreatyClaimFormSummary