import { Modal } from "components";
import { TreatyClaimForm } from "components/forms";
import { useNavigate } from "react-location";
import { LocationGenerics } from "router/location";
import { useAppSearch } from "_graphql/cache/auth";
import { useTreaty } from "hooks/data/use-treaties";



export default function CreateTreatyClaimContainer({
    open,
    setOpen,
    refetch,
}: {
    open: boolean;
    setOpen: (val: boolean) => void;
    refetch?: () => void;
}) {
    const searchParams = useAppSearch()
    const navigate = useNavigate<LocationGenerics>();
    const { loading: fetchigOffer } = useTreaty({
        treatyId: searchParams?.id || "",
    });





    return (
        <Modal
            open={open}
            setOpen={() => {
                setOpen(false);
                navigate({
                    search(prev) {
                        return {
                            ...prev,
                            rate: undefined,
                            modal: undefined,
                        };
                    },
                });
            }}
            title="Make Claim"
            loading={fetchigOffer}
            description="Make a claim on this Treaty"
            hideActions
        >

            <TreatyClaimForm setClose={setOpen} />
        </Modal>
    );
}



